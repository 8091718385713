import * as Yup from 'yup';

export const applicationSchema = Yup.object({
  dealerShipName: Yup.string().required(),
  dealerShipAddress: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  zipCode: Yup.string()
    .required()
    .test('len', 'Must be exactly 5 characters', val => val.length === 5),
  dealershipEmail: Yup.string().required(),
  dealershipPhoneNumber: Yup.string().required(),
  dealerFirstName: Yup.string().required(),
  dealerLastName: Yup.string().required(),
  dealerAddress: Yup.string().required(),
  dealerCity: Yup.string().required(),
  dealerState: Yup.string().required(),
  dealerZipCode: Yup.string()
    .required()
    .test('len', 'Must be exactly 5 characters', val => val.length === 5),
  dealerEmailAddress: Yup.string().required(),
  dealerCellPhoneNumber: Yup.string().required(),
});
