import { useGetDealer } from '../../queries/useGetDealerInfo.ts';
import { useDealerID } from '../useDealerID.ts';

const useGetDealerState = () => {
  const dealerID = useDealerID();
  const { data: dealerDetails } = useGetDealer(dealerID as string);

  return dealerDetails?.address.state || ('' as string);
};

export default useGetDealerState;
