import axios from 'axios';

import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { PostVerificationRequest } from '@luna-protocol/core/src/types';
import { getDealerIdFromToken } from '@luna-protocol/core/src/utils/getDealerIdFromToken';

const usePostVerification = () => {
  const mutation = useMutation(async (data: PostVerificationRequest) => {
    const resp = await axios.post(`${getBaseApi()}/auth/otp/dealer/verify`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
    if (resp.status !== 200) {
      throw new Error('Invalid verification code');
    }
    localStorage.setItem('user_token', resp.data.token);
    localStorage.setItem('dealer_id', getDealerIdFromToken(resp.data.token));
    return resp.data;
  });

  return {
    postAuth: mutation.mutate,
    ...mutation,
  };
};

export { usePostVerification };
