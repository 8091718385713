import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'expired.title',
    defaultMessage:
      'These documents have expired. Please re-create documents from your portal and have all signatures completed on the same day.',
  },
  body: {
    id: 'expired.body',
    defaultMessage:
      'For additional information about re-creating documents, contact Aqua Recreation Sales at 877-763-0522 ext. 6015 by email to {link}.',
  },
});

export default messages;
