import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dealerName: {
    id: 'dealStatus.dealerName',
    defaultMessage: 'Dealer Name',
  },
  backButton: {
    id: 'dealStatus.backButton',
    defaultMessage: 'Back to pre-qualification leads',
  },
  applicationDetails: {
    id: 'dealStatus.applicationDetails',
    defaultMessage: 'Application details',
  },
  applicantDetails: {
    id: 'dealStatus.applicantDetails',
    defaultMessage: 'Applicant details',
  },
  lastUpdated: {
    id: 'dealStatus.lastUpdated',
    defaultMessage: 'Last updated {date} at {time}',
  },
  makeModel: {
    id: 'dealStatus.makeModel',
    defaultMessage: 'Make/Model',
  },
  preQualAmount: {
    id: 'dealStatus.preEqualAmount',
    defaultMessage: 'Pre-qual amount',
  },
  preQualDate: {
    id: 'dealStatus.preEqualDate',
    defaultMessage: 'Pre-qual date',
  },
  unitAndContractDeliveryDate: {
    id: 'dealStatus.unitAndContractDeliveryDate',
    defaultMessage: 'Unit and contract delivery date',
  },
  primaryApplicant: {
    id: 'dealStatus.primaryApplicant',
    defaultMessage: 'Primary applicant',
  },
  coApplicant: {
    id: 'dealStatus.coApplicant',
    defaultMessage: 'Co-applicant',
  },
  status: {
    id: 'dealStatus.status',
    defaultMessage: 'Status',
  },
  incomeVerification: {
    id: 'dealStatus.incomeVerification',
    defaultMessage: 'Income verification',
  },
  identityVerification: {
    id: 'dealStatus.identityVerification',
    defaultMessage: 'Identity verification',
  },
  cellNumber: {
    id: 'dealStatus.cellNumber',
    defaultMessage: 'Cell number',
  },
  state: {
    id: 'dealStatus.state',
    defaultMessage: 'State',
  },
  zip: {
    id: 'dealStatus.zip',
    defaultMessage: 'Zip',
  },
  resendIdentityVerification: {
    id: 'dealStatus.resendIdentityVerification',
    defaultMessage: 'Resend identity verification',
  },
  buildDeal: {
    id: 'dealStatus.builDeal',
    defaultMessage: 'Build deal',
  },
  resendIdentityVerificationDisclaimer: {
    id: 'dealStatus.resendIdentityVerificationDisclaimer',
    defaultMessage: 'Sending this will send a reminder email to customers that have not yet verified their identity.',
  },
  verified: {
    id: 'dealStatus.verified',
    defaultMessage: 'Verified',
  },
  notVerified: {
    id: 'dealStatus.notVerified',
    defaultMessage: 'Not verified',
  },
  resendIdentityVerificationSuccess: {
    id: 'dealStatus.resendIdentityVerificationMessage',
    defaultMessage: 'Identity verification email sent',
  },
});

export default messages;
