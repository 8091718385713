import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'login.title',
    defaultMessage: 'Sign in',
  },
  emailAddressLabel: {
    id: 'login.emailAddressLabel',
    defaultMessage: 'Email address',
  },
  passwordLabel: {
    id: 'login.passwordLabel',
    defaultMessage: 'Password',
  },
  signInButton: {
    id: 'login.signInButton',
    defaultMessage: 'Sign in',
  },
  logInButton: {
    id: 'login.logInButton',
    defaultMessage: 'log in',
  },
  resetPasswordButton: {
    id: 'login.resetPasswordButton',
    defaultMessage: 'Reset password',
  },
  invalidEmailOrPassword: {
    id: 'login.invalidEmailOrPassword',
    defaultMessage: 'Invalid Email and/or Password. Please try again, reset your password or contact support.',
  },
  invalidEmailOrPasswordLocked: {
    id: 'login.invalidEmailOrPasswordLocked',
    defaultMessage:
      'Invalid Email and/or Password. Please reset your password, contact support or try again in 5 minutes',
  },
});

export default messages;
