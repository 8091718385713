import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export type NotifyApplicantProceedToApplicationRequest = {
  customer_email: string;
  application_link: string;
};

const usePostApplicationLink = () => {
  const mutation = useMutation((req: NotifyApplicantProceedToApplicationRequest) => {
    return axios.post(`${getBaseApi()}/dealer/send-application-link`, JSON.stringify(req), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    send: mutation.mutate,
    ...mutation,
  };
};

export { usePostApplicationLink };
