import Complete from '@luna-protocol/core/src/components/Complete/Complete';
import { useIntl } from 'react-intl';
import messages from './Signed.messages';

const Signed = () => {
  const { formatMessage } = useIntl();

  return (
    <Complete
      title={formatMessage(messages.title)}
      body={[formatMessage(messages.detail1), formatMessage(messages.detail2)]}
    />
  );
};

export default Signed;
