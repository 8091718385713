import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import {
  AuthCodeValues,
  PostVerificationConsumerRequest,
  PostVerificationConsumerResponse,
  PostVerificationRequest,
  SendCodeConsumerRequest,
  SendCodeEmailRequest,
  VerificationCode,
} from '../../types';
import messages from './Verification.messages';

import { AxiosResponse } from 'axios';
import { UseMutateFunction } from 'react-query';
import useToast from '../../utils/useToast.ts';
import LineBreak from '../LineBreak/LineBreak.tsx';
import './Verification.scss';

export type VerificationProps = {
  resendCode?: () => { sendCode: UseMutateFunction<AxiosResponse, unknown, SendCodeEmailRequest, unknown> };
  resendCodeCustomer?: () => { sendCode: UseMutateFunction<unknown, unknown, SendCodeConsumerRequest, unknown> };
  submitCode?: () => {
    postAuth: UseMutateFunction<AxiosResponse, unknown, PostVerificationRequest, unknown>;
  };
  submitCodeCustomer?: () => {
    postAuth: UseMutateFunction<PostVerificationConsumerResponse, unknown, PostVerificationConsumerRequest, unknown>;
  };
};

const Verification = ({
  resendCode: resendCodeHook,
  resendCodeCustomer: resendCodeCustomerHook,
  submitCodeCustomer: submitCodeCustomerHook,
  submitCode: submitCodeHook,
}: VerificationProps) => {
  const { formatMessage } = useIntl();
  const [errorSubmitting, setErrorSubmitting] = useState(false);
  const { createToast } = useToast();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { emailAddress, to, blockedTo, newTab, autoSend, customerID, loanApplicationID, from } = state as {
    emailAddress?: string;
    to?: string;
    from?: string;
    newTab?: string;
    blockedTo?: string;
    autoSend?: boolean;
    customerID?: string;
    loanApplicationID?: string;
  };

  const { sendCode: resendCode } = resendCodeHook ? resendCodeHook() : { sendCode: undefined };
  const { sendCode: resendCodeCustomer } = resendCodeCustomerHook ? resendCodeCustomerHook() : { sendCode: undefined };
  const { postAuth: submitCode } = submitCodeHook ? submitCodeHook() : { postAuth: undefined };
  const { postAuth: submitCodeCustomer } = submitCodeCustomerHook ? submitCodeCustomerHook() : { postAuth: undefined };

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (autoSend) {
      resendEmail();
    }
  }, [autoSend]);

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        setIsClicked(false);
      }, 10000);
    }
  }, [isClicked]);

  const initialValues: AuthCodeValues = {
    authCode0: '',
    authCode1: '',
    authCode2: '',
    authCode3: '',
    authCode4: '',
    authCode5: '',
  };

  const [values, setValues] = useState<AuthCodeValues>(initialValues);

  const concatValues = (values: AuthCodeValues) => {
    let authCode: string = '';
    Object.values(values).forEach(value => {
      authCode += value;
    });
    return authCode as VerificationCode;
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteBoard = e.clipboardData?.getData('Text');

    if (pasteBoard) {
      const authCode = Array.from(pasteBoard).slice(0, 6);

      setValues({
        authCode0: authCode[0],
        authCode1: authCode[1],
        authCode2: authCode[2],
        authCode3: authCode[3],
        authCode4: authCode[4],
        authCode5: authCode[5],
      });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, x: number) => {
    const target = e.target;
    if (!isNaN(parseInt(target.value))) {
      setValues({
        ...values,
        [`authCode${x}`]: target.value,
      });
      (target.nextSibling as HTMLElement)?.focus();
    }
  };

  const resendEmail = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e?.preventDefault();
    if (isClicked) {
      return;
    }
    setIsClicked(true);
    if (resendCodeCustomer && customerID && loanApplicationID) {
      resendCodeCustomer({
        method: 'Email',
        customer_id: customerID,
        loan_application_id: loanApplicationID,
      } as SendCodeConsumerRequest);
    } else if (resendCode) {
      resendCode({
        email: emailAddress,
        method: 'Email',
      } as SendCodeEmailRequest);
    }
    createToast({
      title: `${formatMessage(messages.emailResent)}`,
      status: 'success',
    });
  };

  const onSubmit = () => {
    const concatenatedValues: VerificationCode = concatValues(values);

    if (submitCodeCustomer && customerID && loanApplicationID && blockedTo && to) {
      submitCodeCustomer(
        {
          customer_id: customerID,
          loan_application_id: loanApplicationID,
          method: 'Email',
          verification_code: concatenatedValues,
        },
        {
          onSuccess: data => {
            if (data.application_verification_status === 'Blocked') {
              navigate(blockedTo);
            } else {
              if (newTab) {
                window.open(newTab, '_blank', 'noreferrer');
              }
              navigate(to);
            }
          },
          onError: context => {
            console.error('error', context);
            setErrorSubmitting(true);
          },
        },
      );
    } else if (submitCode && emailAddress) {
      submitCode(
        {
          email: emailAddress,
          method: 'Email',
          verification_code: concatenatedValues,
        },
        {
          onSuccess: () => {
            if (to) {
              if (newTab) {
                window.open(newTab, '_blank', 'noreferrer');
              }
              navigate(to);
            }
          },
          onError: context => {
            console.error('error', context);
            setErrorSubmitting(true);
          },
        },
      );
    }
  };

  return (
    <>
      {from ? <BackButton path={from} inverted /> : <BackButton path="/login" inverted />}
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        <p>
          {formatMessage(messages.body)}
          <LineBreak />
          {emailAddress !== undefined ? emailAddress : formatMessage(messages.noEmailAddress)}
        </p>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <p>{formatMessage(messages.label)}</p>
              <div className="digit-grid">
                {[0, 1, 2, 3, 4, 5].map(x => (
                  <Field
                    className="digit-box"
                    key={`authCode${x}`}
                    id={`authCode${x}`}
                    onPaste={onPaste}
                    autoFocus={x === 0}
                    name={`authCode${x}`}
                    value={values[`authCode${x}` as keyof AuthCodeValues]}
                    inputMode="numeric"
                    maxLength={1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, x)}
                  />
                ))}
              </div>
              {errorSubmitting && <p className="error">{formatMessage(messages.error)}</p>}
              <p>
                {formatMessage(messages.resend, {
                  method: (
                    <a
                      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => resendEmail(e)}
                      target="_blank"
                      href=""
                      className="resend-text">
                      {formatMessage(messages.resend.methodText)}
                    </a>
                  ),
                })}
              </p>
              <LineBreak />
              <ButtonGroup>
                <Button type="reset" onClick={() => setValues(initialValues)} testId="verify-reset">
                  {formatMessage(messages.clear)}
                </Button>
                <Button
                  type="submit"
                  variant="secondary"
                  disabled={Object.values(values).some(value => value === '')}
                  testId="verify-continue">
                  {formatMessage(messages.submit)}
                </Button>
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Body>
    </>
  );
};

export default Verification;
