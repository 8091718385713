import { DealInformation } from '@luna-protocol/core/src/types';
import { OEMType as EOEMType } from '@luna-protocol/core/src/types.ts';
import React, { useReducer, useState } from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { ProgramProps } from './components/ProgramSelect/ProgramSelect';
import { PostSerialResponse } from './queries/useGetSerialNumber';
import dealReducer from './reducers/dealReducer';
import { DealerInfo, FlowTemplate, OrderSummary, UserDetails } from './types';

type AppContextProviderProps = {
  children: React.ReactNode;
  initialValues?: Partial<ContextDefaults>;
};

export type ApplicationSummaryDetails = {
  item_name: string;
  serial_numbers: [
    {
      serial_number_type: string;
      serial_number: string;
      product_price: number;
    },
  ];
  model: string;
  color: string;
  approved_amount?: number;
};

export type ContextDefaults =
  | {
      userDetails?: UserDetails;
      setUserDetails: React.Dispatch<React.SetStateAction<UserDetails | undefined>>;
      prequalAmount?: string;
      setPrequalAmount: React.Dispatch<React.SetStateAction<string | undefined>>;
      deal?: Partial<DealInformation>;
      setDeal: (dealOrPartialDeal: Partial<DealInformation>) => void;
      applicationSummaryDetails: ApplicationSummaryDetails | undefined;
      setApplicationSummaryDetails: React.Dispatch<React.SetStateAction<ApplicationSummaryDetails | undefined>>;
      floorplanDetails: Map<string, PostSerialResponse>;
      setFloorplanDetails: React.Dispatch<React.SetStateAction<Map<string, PostSerialResponse>>>;
      loanApplicationID?: string;
      customerName?: string;
      setCustomerName: React.Dispatch<React.SetStateAction<string | undefined>>;
      setLoanApplicationID: React.Dispatch<React.SetStateAction<string>>;
      selectedProgram: ProgramProps | undefined;
      setSelectedProgram: React.Dispatch<React.SetStateAction<ProgramProps | undefined>>;
      OEMType?: EOEMType;
      setOEMType: React.Dispatch<React.SetStateAction<EOEMType>>;
      serialNumbers?: OrderSummary;
      setSerialNumbers: (payload: OrderSummary | undefined) => void;
      setDealerFlowTemplate: React.Dispatch<React.SetStateAction<FlowTemplate | undefined>>;
      dealerFlowTemplate: FlowTemplate | undefined;
      dealerInfo?: DealerInfo | undefined;
      setDealerInfo: React.Dispatch<React.SetStateAction<DealerInfo | undefined>>;
    }
  | Record<string, never>;

export const AppContext = React.createContext({} as ContextDefaults);

export const AppContextProvider = ({ initialValues, children }: AppContextProviderProps) => {
  const [userDetails, setUserDetails] = useSessionStorage<UserDetails | undefined>('userDetails', undefined);
  const [prequalAmount, setPrequalAmount] = useSessionStorage<string | undefined>('prequalAmount', undefined);
  const [applicationSummaryDetails, setApplicationSummaryDetails] = useSessionStorage<
    ApplicationSummaryDetails | undefined
  >('applicationSummaryDetails', undefined);
  const [floorplanDetails, setFloorplanDetails] = useState<Map<string, PostSerialResponse>>(new Map());
  const [loanApplicationID, setLoanApplicationID] = useSessionStorage<string>('loanApplication_id', '');
  const [customerName, setCustomerName] = useState<string | undefined>('');
  const [selectedProgram, setSelectedProgram] = useState<ProgramProps | undefined>(undefined);
  const [OEMType, setOEMType] = useSessionStorage<EOEMType>('OEMType', EOEMType.Brunswick);
  const [serialNumbers, setSerialNumbers] = useReducer(
    (
      state: OrderSummary | undefined,
      action: { type: string; payload: OrderSummary | undefined },
    ): OrderSummary | undefined => {
      const newState = { ...state, ...action.payload } as OrderSummary;
      return { ...newState };
    },
    undefined,
  );
  const [dealerFlowTemplate, setDealerFlowTemplate] = useLocalStorage<FlowTemplate | undefined>(
    'flow_template',
    undefined,
  );
  const [dealerInfo, setDealerInfo] = useState<DealerInfo | undefined>();
  const [deal, setDeal] = useReducer(
    (
      state: Partial<DealInformation> | undefined,
      action: { type: string; payload: Partial<DealInformation> },
    ): Partial<DealInformation> | undefined => {
      return dealReducer({ ...state, ...action.payload }, serialNumbers);
    },
    undefined,
  );

  return (
    <AppContext.Provider
      value={{
        userDetails,
        setUserDetails,
        prequalAmount,
        setPrequalAmount,
        deal,
        setDeal: (dealOrPartialDeal: Partial<DealInformation>) =>
          setDeal({ type: 'SET_DEAL', payload: dealOrPartialDeal }),
        applicationSummaryDetails,
        setApplicationSummaryDetails,
        floorplanDetails,
        setFloorplanDetails,
        loanApplicationID,
        setLoanApplicationID,
        customerName,
        setCustomerName,
        selectedProgram,
        setSelectedProgram,
        OEMType,
        setOEMType,
        serialNumbers,
        setSerialNumbers: (payload: OrderSummary | undefined) =>
          setSerialNumbers({ type: 'SET_SERIAL_NUMBERS', payload }),
        dealerFlowTemplate,
        setDealerFlowTemplate,
        dealerInfo,
        setDealerInfo,
        ...initialValues,
      }}>
      {children}
    </AppContext.Provider>
  );
};
