import axios from 'axios';
import { useQuery } from 'react-query';
import { ProgramProps } from '../components/ProgramSelect/ProgramSelect';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { PROGRAMS } from './constants';

const getPrograms = async (loanApplicationID: string, term: string): Promise<ProgramProps[]> => {
  const resp = await axios.get(`${getBaseApi()}/dealer/application/${loanApplicationID}/programs/term/${term}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get programs');
  }
  return resp.data.programs as ProgramProps[];
};

type ProgramRequest = {
  loanApplicationID: string;
  term: string;
};

const useGetPrograms = (req: ProgramRequest) => {
  return useQuery(
    [PROGRAMS, req.loanApplicationID, req.term],
    async () => {
      return await getPrograms(req.loanApplicationID, req.term);
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );
};

export { useGetPrograms };
