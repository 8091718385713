import axios from 'axios';
import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

type AdditionalInfoRequest = {
  primary_use: string;
  options_included: [string] | [];
  stored_at_customer: boolean;
  stored_address?: {
    address_lines: [string];
    city: string;
    state: string;
    zip_code: string;
  };
  customer_primary_address_valid: boolean;
  customer_primary_address?: {
    address_lines: [string];
    city: string;
    state: string;
    zip_code: string;
  };
};

const usePostAdditionalInfo = (loan_application_id: string) =>
  useMutation((params: AdditionalInfoRequest) =>
    axios.post(`${getBaseApi()}/dealer/application/${loan_application_id}/additional_info`, JSON.stringify(params), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    }),
  );

export { usePostAdditionalInfo };
