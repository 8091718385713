import './NotFound.scss';
import { NavLink } from 'react-router-dom';

export type NotFoundProps = {
  linkBack?: string;
};

const NotFound = ({ linkBack }: NotFoundProps) => (
  <div className="notFound-wrapper">
    <h3>404 Page not found</h3>
    <p>We are sorry but the page you are looking for does not exist.</p>
    {linkBack && <NavLink to={linkBack}> Click here to return </NavLink>}
  </div>
);

export default NotFound;
