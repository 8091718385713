import React from 'react';
import './Card.scss';

interface CardProps {
  className?: string;
  children?: React.ReactNode;
  variant?: 'default' | 'grey';
}
const Card = ({ children, variant = 'default', className }: CardProps) => {
  return <div className={`card ${variant && variant}${className ? ' ' + className : ''}`}>{children}</div>;
};

export default Card;
