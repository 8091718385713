import axios from 'axios';
import { useQuery } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

import { DEALER_INFO } from './constants';
import { DealerInfo } from '../types';

export const getDealer = async (dealerID: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/dealer/${dealerID}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Error fetching dealer');
  }

  return resp.data as DealerInfo;
};

const useGetDealer = (dealerID?: string) => {
  return useQuery(
    [DEALER_INFO, dealerID],
    async () => {
      if (dealerID === undefined) {
        throw new Error('dealer ID is undefined');
      }
      return await getDealer(dealerID);
    },
    { enabled: true },
  );
};
export { useGetDealer };
