import './SelectInput.scss';
import Select, { ActionMeta, OnChangeValue, Options } from 'react-select';
import Creatable from 'react-select/creatable';
import { SelectOption } from '@luna-protocol/core/src/types';
import { FieldHelperProps, useField } from 'formik';
import { FocusEventHandler } from 'react';

interface SelectInputProps {
  label?: string;
  placeholder?: string;
  value: string | SelectOption | undefined;
  onChange?: (newValue: string | SelectOption | undefined) => void;
  onFormikValueChange?: (newValue: string | SelectOption | undefined) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  disabled?: boolean;
  error?: string;
  disclaimer?: string;
  required?: boolean;
  options: string[] | SelectOption[] | undefined;
  className?: string;
  formik?: boolean;
  isClearable?: boolean;
  allowCreate?: boolean;
  testId?: string;
}

const SelectInput = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  disabled = false,
  formik = true,
  error,
  disclaimer,
  required,
  options,
  onBlur,
  className,
  isClearable = false,
  allowCreate = false,
  testId,
  onFormikValueChange,
}: SelectInputProps) => {
  let formikHelpers: FieldHelperProps<unknown>;
  if (formik) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, , helpers] = useField(name || '');
    formikHelpers = helpers;
  }

  function makeOptions(options: string[] | SelectOption[] | undefined): Options<SelectOption> {
    if (options === undefined) {
      return [];
    }

    // Check if the first element is already of type SelectOption
    if (typeof options[0] === 'object' && 'value' in options[0] && 'label' in options[0]) {
      return options as SelectOption[];
    }

    return options.map(o => ({ value: o, label: o })) as SelectOption[];
  }

  function makeValue(value: string | SelectOption | undefined): SelectOption | undefined {
    if (value === undefined) {
      return undefined;
    }
    if (typeof value === 'string') {
      return { value: value, label: value };
    }

    return value as SelectOption;
  }

  const handleInputChange = (e: OnChangeValue<SelectOption, false>, a: ActionMeta<SelectOption>) => {
    if (!onChange) {
      return;
    }
    if (e === undefined || e?.value === '') {
      onChange(undefined);
      return;
    }

    switch (a.action) {
      case 'clear':
        if (
          allowCreate ||
          (options && typeof options[0] === 'object' && 'value' in options[0] && 'label' in options[0])
        ) {
          if (formik) {
            formikHelpers.setValue(undefined);
            if (onFormikValueChange) {
              onFormikValueChange(undefined);
            }
          }
          break;
        }
        onChange(undefined);
        break;
      case 'select-option':
      case 'create-option':
        // if options is already SelectOption, just return e
        if (
          allowCreate ||
          (options && typeof options[0] === 'object' && 'value' in options[0] && 'label' in options[0])
        ) {
          if (formik) {
            formikHelpers.setValue(e, true);
            if (onFormikValueChange) {
              onFormikValueChange(e as unknown as SelectOption);
            }
          }
        }

        onChange && onChange((e as unknown as SelectOption).value);
        break;
    }
  };

  return (
    <div className={`select--input--container ${className ? className : ''}`}>
      <label htmlFor={name} className="label">
        {label}
      </label>
      {allowCreate ? (
        <Creatable
          isClearable={isClearable}
          classNames={{
            control: () => 'select-input',
          }}
          name={name}
          id={name}
          controlShouldRenderValue
          onBlur={onBlur}
          onChange={handleInputChange}
          value={makeValue(value)}
          isDisabled={disabled}
          required={required}
          placeholder={placeholder}
          data-testid={`${testId || name}-select-input`}
          options={makeOptions(options)}
        />
      ) : (
        <Select
          isClearable={isClearable}
          classNames={{
            control: () => 'select-input',
          }}
          name={name}
          id={name}
          controlShouldRenderValue
          onBlur={onBlur}
          onChange={handleInputChange}
          value={makeValue(value)}
          isDisabled={disabled}
          required={required}
          placeholder={placeholder}
          data-testid={`${testId || name}-select-input`}
          options={makeOptions(options)}
        />
      )}
      <div className="invalid-feedback">
        <p className="error">{error}</p>
      </div>
      <p className="disclaimer">{disclaimer}</p>
    </div>
  );
};

export default SelectInput;
