import axios from 'axios';
import { useQuery } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { PROGRAM_TERMS } from './constants';

const getProgramTerms = async (loanApplicationID: string): Promise<number[]> => {
  const resp = await axios.get(`${getBaseApi()}/dealer/application/${loanApplicationID}/programs/term`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get programs');
  }
  return resp.data.terms as number[];
};

const useGetProgramTerms = (loanApplicationID: string) => {
  return useQuery(
    [PROGRAM_TERMS, loanApplicationID],
    async () => {
      return await getProgramTerms(loanApplicationID);
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );
};

export { useGetProgramTerms };
