import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { DEALER_APPLICATION } from './constants';

export interface PostDealerApplicationRequest {
  dealer: {
    address: {
      address_lines: [string];
      city: string;
      state: string;
      zip_code: string;
    };
    cell_phone_number: string;
    email: string;
    name: string;
  };
  lead_user: {
    address: {
      address_lines: [string];
      city: string;
      state: string;
      zip_code: string;
    };
    cell_phone_number: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}

const submitApplication = async (data: PostDealerApplicationRequest) => {
  const resp = await axios.post(`${getBaseApi()}/admin/dealer-invite`, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (!resp || resp.status !== 200) {
    throw new Error('Error submitting application');
  }

  return resp.data;
};

const usePostDealerApplication = () => {
  const mutation = useMutation({
    mutationKey: [DEALER_APPLICATION],
    mutationFn: submitApplication,
  });

  return {
    submitApplication: mutation.mutate,
    ...mutation,
  };
};

export { usePostDealerApplication };
