import axios from 'axios';
import { useQuery } from 'react-query';
import { RATE_SHEET } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface RateSheetResponse {
  dealerParticipationFee: number;
  dealerParticipationPerRate: number;
  maxDealerParticipationFee: number;
  promoDealerParticipation: number;
  dealerParticipationByLoanAmount: number[];
  maxFrontEndPercentNewProduct: number;
  maxBackendPercentNewProduct: number;
  maxBackendAmountNewProduct: number;
  maxFrontEndPercentUsedProduct: number;
  maxBackendPercentUsedProduct: number;
  maxBackendAmountUsedProduct: number;
  maxOverallNewProduct: number;
  maxOverallUsedProduct: number;
  maxBackendOverall: number;
  approvalValidDays: number;
  calculationType: string;
  oemName: string;
  backEndProducts: string[];
  frontEndProducts: string[];
  termsByAmount: TermsByAmount[];
}

export interface TermsByAmount {
  minAmountFinanced?: number;
  maxAmountFinanced?: number;
  terms: number[];
}

export const getRatesheet = async (oem: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/rate_sheet/${oem}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get rate sheet info');
  }
  return resp.data as RateSheetResponse;
};

const useGetRatesheet = (oem?: string) => {
  return useQuery(
    [RATE_SHEET, oem],
    async () => {
      if (oem === undefined) {
        throw new Error('OEM is undefined');
      }
      return await getRatesheet(oem);
    },
    {
      enabled: oem != undefined,
    },
  );
};
export { useGetRatesheet };
