import { Route } from 'react-router-dom';

import Screen from '@luna-protocol/core/src/components/Screen/Screen';
import Login from '@luna-protocol/core/src/components/Login/Login.tsx';
import NotFound from '@luna-protocol/core/src/components/NotFound/NotFound';
import SignOut from '@luna-protocol/core/src/components/SignOut/SignOut.tsx';
import ApplicationForm from './components/ApplicationForm/ApplicationForm';
import Verification from '@luna-protocol/core/src/components/Verification/Verification.tsx';
import Create from './components/CreateAccount/CreateAccount';
import AddProducts from './components/AddProducts/AddProducts';
import PrequalificationList from './components/PrequalificationList/PrequalificationList';
import DealStatus from './components/DealStatus/DealStatus.tsx';
import AmendDetails from './components/AmendDetails/AmendDetails';
import ProgramSelect from './components/ProgramSelect/ProgramSelect';
import Users from './components/Users/Users';
import RetrievePrequalification from './components/RetrievePrequalification/RetrievePrequalification.tsx';
import { usePostLogin } from './queries/usePostLogin.ts';
import { usePostVerification } from './queries/usePostVerification.ts';
import { useSendCode } from './queries/useSendCode.ts';
import ReviewDeal from './components/ReviewDeal/ReviewDeal.tsx';
import DealerComplete from './components/DealerComplete/DealerComplete.tsx';
import NewLead from './components/NewLead/NewLead.tsx';
import Processing from './components/Processing/Processing.tsx';
import { useSendConsent } from './queries/useSendConsent.ts';
import { useVerifyConsent } from './queries/useVerifyConsent.ts';
import PasswordReset from '@luna-protocol/core/src/components/PasswordReset/PasswordReset.tsx';
import { usePatchPassword } from './queries/usePatchPassword.ts';
import { useSendLegal } from './queries/useSendLegal.ts';
import SignDocs from './components/SignDocs/SignDocs.tsx';
import { OEMType } from '@luna-protocol/core/src/types.ts';
import Expired from './components/Expired/Expired.tsx';
import InstructionPage from './components/InstructionPage/InstructionPage.tsx';
import Signed from './components/Signed/Signed.tsx';
import PricingError from './components/PricingError/PricingError.tsx';

const routes = (
  <Route
    element={
      <Screen
        navigationLinks={[
          { label: 'Prequalifications', url: '/prequalification' },
          { label: 'Users', url: '/users' },
          { label: 'Sign Out', url: '/signout' },
        ]}
      />
    }>
    <Route path="/login" element={<Login login={usePostLogin} postLoginRoute={'/prequalification'} />} />
    <Route
      path="/login/ktm"
      element={<Login login={usePostLogin} postLoginRoute={'/prequalification'} oem_type={OEMType.KTM} />}
    />
    <Route
      path="/login/ezgo"
      element={<Login login={usePostLogin} postLoginRoute={'/prequalification'} oem_type={OEMType.EZGO} />}
    />
    <Route path="password_reset" element={<PasswordReset patchPassword={usePatchPassword} />} />
    <Route path="/signout" element={<SignOut />} />
    <Route path="/dealer_application" element={<ApplicationForm />} />
    <Route
      path="/email_verification"
      element={<Verification resendCode={useSendCode} submitCode={usePostVerification} />}
    />
    <Route
      path="/customer_consent"
      element={<Verification resendCode={useSendConsent} submitCode={useVerifyConsent} />}
    />
    <Route path="/customer_legal" element={<Verification resendCode={useSendLegal} submitCode={useVerifyConsent} />} />
    <Route path="/accept_invite" element={<Create />} />
    <Route path="/prequalification" element={<PrequalificationList />} />
    <Route path="/deal_status/:loan_application_id" element={<DealStatus />} />
    <Route path="/new_lead" element={<NewLead />} />
    <Route path="/email_sent" element={<InstructionPage />} />
    <Route path="/retrieve" element={<RetrievePrequalification />} />
    <Route path="/amend_details" element={<AmendDetails />} />
    <Route path="/add_products" element={<AddProducts />} />
    <Route path="/processing" element={<Processing />} />
    <Route path="/pricing_error" element={<PricingError />} />
    <Route path="/select_program" element={<ProgramSelect />} />
    <Route path="/review_deal" element={<ReviewDeal />} />
    <Route path="/complete" element={<DealerComplete />} />
    <Route path="/sign_docs" element={<SignDocs />} />
    <Route path="/signed" element={<Signed />} />
    <Route path="/expired" element={<Expired />} />
    <Route path="/complete-signed" element={<DealerComplete signed />} />
    <Route path="/users" element={<Users />} />
    <Route path="*" element={<NotFound linkBack={'/login'} />} />
  </Route>
);

export default routes;
