import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'prequalificationList.title',
    defaultMessage: 'Pre-qualification leads',
  },
  dealerName: {
    id: 'prequalificationList.dealerName',
    defaultMessage: 'Dealership',
  },
  firstName: {
    id: 'prequalificationList.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'prequalificationList.lastName',
    defaultMessage: 'Last Name',
  },
  make: {
    id: 'prequalificationList.make',
    defaultMessage: 'Make/Model',
  },
  model: {
    id: 'prequalificationList.model',
    defaultMessage: 'Make/Model',
  },
  phone_number: {
    id: 'prequalificationList.phone_number',
    defaultMessage: 'Cell Number',
  },
  state: {
    id: 'prequalificationList.state',
    defaultMessage: 'State',
  },
  zip_code: {
    id: 'prequalificationList.zip_code',
    defaultMessage: 'ZIP',
  },
  borrowing_amount: {
    id: 'prequalificationList.borrowing_amount',
    defaultMessage: 'Pre-qual amount',
  },
  idv_status: {
    id: 'prequalificationList.idv_status',
    defaultMessage: 'IDV status',
  },
  created_at: {
    id: 'prequalificationList.created_at',
    defaultMessage: 'Date of pre-qualification',
  },
  retrieve: {
    id: 'prequalificationList.retrieve',
    defaultMessage: 'Retrieve application',
  },
  startPrequalification: {
    id: 'prequalificationList.startPrequalification',
    defaultMessage: 'Start pre-qualification',
  },
  status: {
    id: 'prequalificationList.status',
    defaultMessage: 'Status',
  },
  actionHeader: {
    id: 'prequalificationList.actionHeader',
    defaultMessage: 'Action',
  },
  actionButton: {
    id: 'prequalificationList.actionButton',
    defaultMessage: 'Build deal',
  },
});

export default messages;
