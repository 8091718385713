import axios from 'axios';
import { useQuery } from 'react-query';
import { RETRIEVE_DEAL_STATUS } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { DealStatus } from '@luna-protocol/core/src/types';

const getDealStatus = async (loanApplicationID: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/application/${loanApplicationID}/summary`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get deal info');
  }
  return resp.data as DealStatus;
};

const useGetDealStatus = (loanApplicationID?: string) => {
  return useQuery(
    [RETRIEVE_DEAL_STATUS, loanApplicationID],
    async () => {
      if (loanApplicationID === undefined) {
        throw new Error('Loan application ID is undefined');
      }
      return await getDealStatus(loanApplicationID);
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};
export { useGetDealStatus };
