/**
 * Component: Button
 *
 * A customizable button component for React applications.
 *
 * @param {string} [role='button'] - The role of the button.
 * @param {boolean} [disabled=false] - If true, the button will be disabled.
 * @param {ButtonType} [type] - The type of the button.
 * @param {string} [name] - The name of the button.
 * @param {string} [value] - The value of the button.
 * @param {React.ReactNode} children - The content of the button.
 * @param {function} onClick - The function to be called when the button is clicked.
 * @param {ButtonVariant} [variant='primary'] - The variant of the button.
 * @param {string} [testId] - The test id of the button.
 * @param {string} [className] - The class name of the button.
 * @param {boolean} [fullWidth=true] - If true, the button will take full width.
 * @param {number} [width] - The width of the button.
 * @returns {React.ReactElement}
 *
 * @example
 * <Button name="Test" onClick={onClick}>
 *    Test
 * </Button>
 *
 * @example
 * <Button name="Test" onClick={onClick} disabled>
 *    Test
 * </Button>
 *
 * @example
 * <Button name="Test" onClick={onClick} variant="secondary">
 *    Test
 * </Button>
 *
 * @example
 * <Button name="Test" onClick={onClick} fullWidth={false}>
 *    Test
 * </Button>
 */

import React from 'react';
import './Button.scss';
import PendingSpinner from '../PendingSpinner/PendingSpinner';

type ButtonType = 'button' | 'submit' | 'reset';
type ButtonVariant = 'primary' | 'secondary';

interface ButtonProps {
  className?: string;
  role?: string;
  disabled?: boolean;
  type?: ButtonType;
  name?: string;
  value?: string;
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
  variant?: ButtonVariant;
  testId?: string;
  fullWidth?: boolean;
  width?: number;
  pending?: boolean;
}

const Button = ({
  role = 'button',
  disabled = false,
  type,
  name,
  value,
  children,
  onClick,
  variant = 'primary',
  testId,
  className,
  fullWidth = true,
  width,
  pending = false,
}: ButtonProps) => (
  <button
    role={role}
    className={`button ${variant} ${fullWidth ? `full-width` : ''} ${className ? ` ${className}` : ''} ${pending ? 'pending' : ''}`}
    disabled={disabled || pending}
    type={type}
    name={name}
    title={name}
    value={value}
    onClick={onClick}
    style={{ width: width ? width : undefined, minWidth: width && 'unset' }}
    data-testid={testId === undefined ? `button-${name}` : testId}
    aria-label={name}>
    {!pending ? children : <PendingSpinner />}
  </button>
);

export default Button;
