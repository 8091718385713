import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'instructionPage.title',
    defaultMessage:
      'An email has been sent to the customer(s) to accept the relevant disclosures. Once accepted, details will appear in your portal.',
  },
  body: {
    id: 'instructionPage.body',
    defaultMessage: 'Please close this window to return to your portal',
  },
});

export default messages;
