import axios from 'axios';
import { useQuery } from 'react-query';
import { RETRIEVE_DEAL } from './constants';
import { DealInformation } from '@luna-protocol/core/src/types';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export const getDealInformation = async (loanApplicationID: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/application/${loanApplicationID}/deal`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get deal info');
  }
  return resp.data as DealInformation;
};

const useGetDealInformation = (loanApplicationID?: string) => {
  return useQuery(
    [RETRIEVE_DEAL, loanApplicationID],
    async () => {
      if (loanApplicationID === undefined) {
        throw new Error('Loan application ID is undefined');
      }
      return await getDealInformation(loanApplicationID);
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};

export { useGetDealInformation };
