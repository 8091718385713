import * as Yup from 'yup';

export const newLeadSchema = Yup.object({
  email_address: Yup.string().email().required('Please provide an email address'),
  collateral: Yup.array()
    .of(
      Yup.object().shape({
        make: Yup.object().required(),
        model: Yup.object().required(),
        selling_price: Yup.number().min(0.1, 'Please provide a valid price').required('Please provide a selling price'),
        year: Yup.number().required('Please provide a year'),
        nada_msrp_invoice: Yup.number().when('title', ([title], schema) => {
          if ((title ?? '').includes('Trailer') || (title ?? '').includes('Engine')) {
            return schema.notRequired();
          }
          return schema.min(0.1, 'Please provide a valid price').required('Please provide a NADA/MSRP/Invoice price');
        }),
        condition: Yup.object().required('Please provide a condition'),
        asset_class: Yup.object().required('Please provide an asset class'),
        title: Yup.string(),
      }),
    )
    .required('Please select a collateral'),
  paperApplication: Yup.string().oneOf(['yes', 'no']).required('Please select yes or no for paper application'),
});
