import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from './BackButton.messages';
import './BackButton.scss';
import arrow from '../../assets/backArrow.svg';
import arrowInverted from '../../assets/backArrow-inverted.svg';

type BackButtonProps = {
  path?: string;
  inverted?: boolean;
};

const BackButton = ({ path, inverted }: BackButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={`BackButton ${inverted ? 'inverted' : ''}`}>
      <Link to={`${path}`}>
        <div>
          <img src={inverted ? arrowInverted : arrow} aria-label="Back Button" alt="Back Button" />
          {formatMessage(messages.title)}
        </div>
      </Link>
    </div>
  );
};

export default BackButton;
