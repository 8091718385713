import './LineBreak.scss';

type LineBreakProps = {
  bordered?: boolean;
  largeMargin?: boolean;
};

const LineBreak = ({ bordered = false, largeMargin = false }: LineBreakProps) => {
  return <div className={`line-break ${bordered ? 'bordered' : ''} ${largeMargin ? 'large-margin' : ''}`}></div>;
};

export default LineBreak;
