import axios from 'axios';
import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

type FinaliseRequest = {
  delivery_date: string | undefined;
  dealer_email_override: string | null;
};

const usePostFinalise = (loan_application_id: string) => {
  const mutation = useMutation((params: FinaliseRequest) => {
    return axios.post(`${getBaseApi()}/dealer/application/${loan_application_id}/finalise`, JSON.stringify(params), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    finaliseApplication: mutation.mutate,
    ...mutation,
  };
};

export { usePostFinalise };
