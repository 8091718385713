import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'adminPasswordReset.title',
    defaultMessage: 'Create your password',
  },
  description: {
    id: 'adminPasswordReset.description',
    defaultMessage: 'Please enter your chosen password',
  },
  passwordLabel: {
    id: 'adminPasswordReset.passwordLabel',
    defaultMessage: 'Password',
  },
  passwordConfirmLabel: {
    id: 'adminPasswordReset.passwordConfirmLabel',
    defaultMessage: 'Re-enter Password',
  },
  createPasswordLabel: {
    id: 'adminPasswordReset.createPasswordLabel',
    defaultMessage: 'Create Password',
  },
  errorListTitle: {
    id: 'adminPasswordReset.errorListTitle',
    defaultMessage: 'Please be aware that your password must:',
  },
  errorListComparison: {
    id: 'adminPasswordReset.errorListComparison',
    defaultMessage:
      'Not be comprised of given name (first name), surname (last name), full name, display name, or email address',
  },
  errorListReuse: {
    id: 'adminPasswordReset.errorListReuse',
    defaultMessage: 'Not reuse previous passwords (up to 5 previous passwords)',
  },
});

export default messages;
