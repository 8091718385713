import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import DataTable from '@luna-protocol/core/src/components/DataTable/DataTable.tsx';
import Input from '@luna-protocol/core/src/components/Input/Input';
import Modal from '@luna-protocol/core/src/components/Modal/Modal';
import SelectInput from '@luna-protocol/core/src/components/SelectInput/SelectInput';
import coreMessages from '@luna-protocol/core/src/utils/Core.messages.ts';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import { AxiosError } from 'axios';
import { USERS } from '../../queries/constants.ts';
import { useGetUsers, User } from '../../queries/useGetUsers.ts';
import { useInviteUser } from '../../queries/useInviteUser.ts';
import { usePutSuspendDealer } from '../../queries/usePutSuspendDealer.ts';
import { userActions } from '../../utils/constants.ts';
import { useDealerID } from '../../utils/useDealerID.ts';
import { inviteSchema } from './InviteSchema.ts';
import messages from './Users.messages.ts';
import './Users.scss';

interface InviteUserProps {
  first_name: string;
  last_name: string;
  email: string;
}

const Users = () => {
  useAuth();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper<User>();
  const { data: users, isLoading } = useGetUsers();
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { suspend } = usePutSuspendDealer();
  const { invite } = useInviteUser();

  const dealerId = useDealerID();
  const { createToast } = useToast();

  const initialValues: InviteUserProps = {
    first_name: '',
    last_name: '',
    email: '',
  };

  const handleChange = (dealer_id: string) => {
    suspend(dealer_id, {
      onSuccess: () => {
        queryClient.invalidateQueries(USERS);
        createToast({
          title: `${formatMessage(messages.suspended)}`,
          status: 'success',
        });
      },
      onError: error => {
        console.log(error);
        createToast({
          title: (error as AxiosError)?.message,
          status: 'error',
        });
      },
    });
  };

  const onSubmit = (values: InviteUserProps) => {
    const inviteRequest = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      dealer_id: (dealerId || 0).toString(),
    };
    setSubmitting(true);
    invite(inviteRequest, {
      onSuccess: () => {
        setSubmitting(false);
        setShowModal(false);
        queryClient.invalidateQueries(USERS);
        createToast({
          title: `${formatMessage(coreMessages.success)}`,
          status: 'success',
        });
      },
      onError: error => {
        setSubmitting(false);
        createToast({
          title: (error as AxiosError)?.message,
          status: 'error',
        });
      },
    });
  };

  const columns: ColumnDef<User, string>[] = [
    columnHelper.accessor('first_name', {
      cell: info => info.getValue(),
      header: `${formatMessage(messages.firstName)}`,
    }),
    columnHelper.accessor('last_name', {
      cell: info => info.getValue(),
      header: `${formatMessage(messages.lastName)}`,
    }),
    columnHelper.accessor('email', {
      cell: info => info.getValue(),
      header: `${formatMessage(messages.email)}`,
    }),
    columnHelper.display({
      cell: props => <>{props.row.original.roles[0]}</>,
      header: `${formatMessage(messages.roles)}`,
    }),
    columnHelper.accessor('status', {
      cell: info => info.getValue(),
      header: `${formatMessage(messages.status)}`,
    }),
    columnHelper.display({
      cell: props => (
        <SelectInput
          formik={false}
          className="action-select"
          placeholder={formatMessage(messages.selectPlaceholder)}
          onChange={() => handleChange(props.row.original.dealer_user_id)}
          value=""
          name={formatMessage(messages.action)}
          options={userActions}
          disabled={props.row.original.status === 'suspended'}
        />
      ),
      header: `${formatMessage(messages.action)}`,
    }),
  ];

  return (
    <Body fullWidth>
      <div className="users-page--wrapper">
        <h1>{formatMessage(messages.title)}</h1>
        <div className="button-container">
          <ButtonGroup align="right">
            <Button className="invite-button" type="submit" variant="secondary" onClick={() => setShowModal(true)}>
              {formatMessage(messages.invite)}
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <DataTable
        index={5}
        data={!isLoading ? users : []}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columns={columns as ColumnDef<User, any>[]}
        isLoading={isLoading}
      />

      {showModal ? (
        <Modal
          onClose={() => setShowModal(false)}
          showCloseButton={false}
          title={formatMessage(messages.inviteTitle)}
          centeredTitle>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={inviteSchema} enableReinitialize>
            {({ values, errors, handleBlur, touched, handleChange }) => (
              <Form>
                <fieldset className="fieldset">
                  <Input
                    label={formatMessage(messages.firstName)}
                    placeholder={formatMessage(messages.firstName)}
                    name="first_name"
                    onChange={handleChange}
                    value={values.first_name}
                    error={!!touched.first_name && errors.first_name ? errors.first_name : undefined}
                    onBlur={handleBlur}
                  />
                  <Input
                    label={formatMessage(messages.lastName)}
                    placeholder={formatMessage(messages.lastName)}
                    name="last_name"
                    onChange={handleChange}
                    value={values.last_name}
                    error={!!touched.last_name && errors.last_name ? errors.last_name : undefined}
                    onBlur={handleBlur}
                  />
                  <Input
                    label={formatMessage(messages.email)}
                    placeholder={formatMessage(messages.email)}
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    error={!!touched.email && errors.email ? errors.email : undefined}
                    onBlur={handleBlur}
                  />
                </fieldset>
                <ButtonGroup fullWidth>
                  <Button type="submit" variant="secondary" pending={submitting} onClick={() => onSubmit(values)}>
                    {formatMessage(messages.submit)}
                  </Button>
                  <Button type="reset" variant="secondary" onClick={() => setShowModal(false)}>
                    {formatMessage(messages.close)}
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : null}
    </Body>
  );
};

export default Users;
