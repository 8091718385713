import { defineMessages } from 'react-intl';

const messages = defineMessages({
  confirm: {
    id: 'vinLookup.confirm',
    defaultMessage: 'confirm',
  },
  netFloorBalance: {
    id: 'vinLookup.netFloorBalance',
    defaultMessage: 'Net floorplan balance',
  },
  boatFinanceEndDate: {
    id: 'vinLookup.boatFinanceEndDate',
    defaultMessage: 'Boat Subsidized Financing End Date',
  },
  motorcycleFinanceEndDate: {
    id: 'vinLookup.motorcycleFinanceEndDate',
    defaultMessage: 'Motorcycle Subsidized Financing End Date',
  },
  golfCartFinanceEndDate: {
    id: 'vinLookup.golfCartFinanceEndDate',
    defaultMessage: 'Golf Cart Subsidized Financing End Date',
  },
  loading: {
    id: 'vinLookup.loading',
    defaultMessage: 'Loading...',
  },
  missing: {
    id: 'vinLookup.missing',
    defaultMessage:
      'No match found in the Wells Fargo database. Please continue if the serial number above is correct, or edit the number if required.',
  },
});

export default messages;
