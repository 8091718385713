import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'dealerComplete.title',
    defaultMessage: 'Thank you!',
  },
  titleSigned: {
    id: 'dealerComplete.title.signed',
    defaultMessage: 'Congratulations, signing is complete!',
  },
  body: {
    id: 'dealerComplete.body',
    defaultMessage: 'The applicants have been notified via email to review, finalize, and sign their documents',
  },
  bodySigned: {
    id: 'dealerComplete.body.signed',
    defaultMessage:
      'Reminder, you have 10 business days to supply trailing documents to Aqua from the contract funding date. For additional information about trailing documents or the contract you just signed, contact Aqua Recreation Sales at 877-763-0522 ext. 6015 by email to {link}.',
  },
  returnButton: {
    id: 'dealerComplete.returnButton',
    defaultMessage: 'Return to home',
  },
  returnButtonSigned: {
    id: 'dealerComplete.returnButton.signed',
    defaultMessage: 'Return to homepage',
  },
  email: {
    id: 'dealerComplete.email',
    defaultMessage: 'recsupport@aquafinance.com',
  },
});

export default messages;
