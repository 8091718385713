import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

const usePutSuspendDealer = () => {
  const mutation = useMutation((dealer_id: string) => {
    return axios.put(`${getBaseApi()}/dealer/users/${dealer_id}/suspend`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    suspend: mutation.mutate,
    ...mutation,
  };
};

export { usePutSuspendDealer };
