/**
 * Functional component representing a container for the body content of a section.
 *
 * @param children - The content to be displayed inside the Body component.
 * @param fullWidth - A boolean indicating whether the Body component should take up full width.
 * @param stretched - A boolean indicating whether the content inside the Body component should be stretched.
 * @param fullWithMargin - A boolean indicating whether the Body component should have full width with margin.
 * @returns A styled div container for the body content with optional styling properties.
 */

import React from 'react';
import './Body.scss';

const Body = ({
  children,
  fullWidth = false,
  stretched = false,
  fullWithMargin = false,
}: {
  children: React.ReactNode | JSX.Element | JSX.Element[];
  fullWidth?: boolean;
  stretched?: boolean;
  fullWithMargin?: boolean;
}) => (
  <div className={`Body ${fullWidth ? 'fullWidth' : ''} ${fullWithMargin ? 'fullWithMargin' : ''}`}>
    <div className={`${stretched ? 'stretched' : ''}`}>{children}</div>
  </div>
);

export default Body;
