import { defineMessages } from 'react-intl';

const messages = defineMessages({
  feesTitle: {
    id: 'feesDeductions.feesTitle',
    defaultMessage: 'Fees and taxes',
  },
  feesTotalTitle: {
    id: 'feesDeductions.feesTotalTitle',
    defaultMessage: 'Total Fees and taxes',
  },
  bepTitle: {
    id: 'feesDeductions.bepTitle',
    defaultMessage: 'Back end products',
  },
  bepTotalTitle: {
    id: 'feesDeductions.bepTotalTitle',
    defaultMessage: 'Total back end products',
  },
  deductionsTitle: {
    id: 'feesDeductions.deductionsTitle',
    defaultMessage: 'Deductions',
  },
  deductionsTotalTitle: {
    id: 'feesDeductions.deductionsTotalTitle',
    defaultMessage: 'Total Deductions',
  },
  warrantyLink: {
    id: 'feesDeductions.warrantyLink',
    defaultMessage: 'Click here to login to the Brunswick Product Protection dealer portal to get relevant pricing',
  },
  addAnother: {
    id: 'feesDeductions.addAnother',
    defaultMessage: 'Add another product',
  },
  publicOfficialsSubtext: {
    id: 'feesDeductions.publicOfficialsSubtext',
    defaultMessage: '*Paid to Public Officials',
  },
  conditionalStateSubtext: {
    id: 'feesDeductions.conditionalStateSubtext',
    defaultMessage: '*Where permitted under State Law',
  },
  floridaSubtext: {
    id: 'feesDeductions.floridaSubtext',
    defaultMessage: '*fee will be withheld from funding - check to itemize and charge to the customer',
  },
});

export default messages;
