import { useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import { Spinner } from '@luna-protocol/core/src/components/Spinner/Spinner';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';

import { AppContext } from '../../AppContext';

import { ContractUrl, useGetContract } from '../../queries/useGetContract.ts';
import messages from './SignDocs.messages.ts';

const SignDocs = () => {
  const location = useLocation();
  useAuth({
    to: location.pathname + location.search,
  });
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const QueryLoanApplicationID = searchParams.get('loan_application_id');
  const { getContract } = useGetContract();
  const [contract_url, setContractUrl] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { setOEMType } = useContext(AppContext);
  const { data: dealerBrandingData } = useGetDealerBranding(searchParams.get('dealer_id') || '');

  useEffect(() => {
    if (dealerBrandingData && dealerBrandingData.oem_type) {
      setOEMType(dealerBrandingData.oem_type);
    }
  }, [dealerBrandingData, setOEMType]);

  useInterval(() => {
    if (!contract_url && QueryLoanApplicationID) {
      getContract(QueryLoanApplicationID, {
        onSuccess: (data: ContractUrl) => {
          setContractUrl(data?.docusign_link);
        },
      });
    }
  }, 1000);

  const hideButtons = !(sessionStorage.getItem('is_standalone_dealer') === 'false');

  return (
    <>
      {hideButtons && <BackButton path="/review_deal" inverted />}
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        {contract_url ? (
          <>
            <p>{formatMessage(messages.detail1)}</p>
            <ButtonGroup>
              {hideButtons && (
                <Button
                  onClick={() => {
                    navigate('/prequalification');
                    sessionStorage.clear();
                  }}>
                  {formatMessage(messages.returnButton)}
                </Button>
              )}
              <NavLink to={contract_url}>
                <Button variant="secondary">{formatMessage(messages.button)}</Button>
              </NavLink>
            </ButtonGroup>
          </>
        ) : (
          <Spinner color={'black'} />
        )}
      </Body>
    </>
  );
};

export default SignDocs;
