import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import getBaseApi from '../../utils/getBaseApi.ts';

const SignOut = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // don't await
    axios.post(`${getBaseApi()}/auth/signout`, null, {
      headers: { Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
    localStorage.removeItem('user_token');
    navigate('/login');
  }, []);
  return <></>;
};

export default SignOut;
