import { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from './Totals.messages.ts';
import './Totals.scss';
import { NumberToPercentage, USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import { AppContext } from '../../AppContext.tsx';

const Totals = () => {
  const { formatMessage } = useIntl();
  const { deal, serialNumbers } = useContext(AppContext);

  if (deal?.totals === undefined) {
    return null;
  }

  return (
    <div className="totals-container">
      <div className="totals-container--title">
        <h2>{formatMessage(messages.title)}</h2>
        <h2>{USDollar.format(serialNumbers?.approved_amount || 0)}</h2>
      </div>
      <div className="totals-container--list">
        <div className="totals-container--column">
          <div className="totals-container--column--content">
            <div className="totals-container--column-title">{formatMessage(messages.basePrice)}</div>
            <div className="totals-container--column-value">{USDollar.format(deal?.totals.basePrice || 0)}</div>
          </div>
        </div>
        <div className="totals-container--column">
          <div className="totals-container--column--content">
            <div className="totals-container--column-title">{formatMessage(messages.feesTitle)}</div>
            <div className="totals-container--column-value">{USDollar.format(deal?.totals.additions || 0)}</div>
          </div>
        </div>
        <div className="totals-container--column">
          <div className="totals-container--column--content">
            <div className="totals-container--column-title">{formatMessage(messages.deductionsTitle)}</div>
            <div className="totals-container--column-value">{USDollar.format(deal?.totals.deductions || 0)}</div>
          </div>
        </div>
        <div className="totals-container--column">
          <div className="totals-container--column--content">
            <div className="totals-container--column-title">{formatMessage(messages.totalRequested)}</div>
            <div className="totals-container--column-value">{USDollar.format(deal?.totals.total_requested || 0)}</div>
          </div>
        </div>
      </div>
      <div className="totals-container--list dropped">
        <div className="totals-container--column dropped">
          <div className="totals-container--column--content">
            <div className="totals-container--column-title">{formatMessage(messages.frontendLTV)}</div>
            <div className={`totals-container--column-value ${deal?.totals.isFrontendLTVExceed ? `negative` : ''}`}>
              {`${USDollar.format(deal?.totals.frontendTotalValue || 0)} - ${NumberToPercentage(deal?.totals.frontendLtvPercentage || 0)}`}
            </div>
          </div>
        </div>
        <div className="totals-container--column dropped">
          <div className="totals-container--column--content">
            <div className="totals-container--column-title">{formatMessage(messages.productsTitle)}</div>
            <div className={`totals-container--column-value ${deal?.totals.isBackendLTVExceed ? `negative` : ''}`}>
              {USDollar.format(deal?.totals.backend_products || 0)}
            </div>
          </div>
        </div>
        <div className="totals-container--column dropped">
          <div className="totals-container--column--content">
            <div className="totals-container--column-title">{formatMessage(messages.backendLTV)}</div>
            <div className={`totals-container--column-value ${deal?.totals.isBackendLTVExceed ? `negative` : ''}`}>
              {`${USDollar.format(deal?.totals.backend_products || 0)} - ${NumberToPercentage(deal?.totals.backendLtvPercentage || 0)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Totals;
