import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import { useIntl } from 'react-intl';
import messages from './InstructionPage.messages';

const InstructionPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body stretched>
        <p>{formatMessage(messages.body)}</p>
      </Body>
    </>
  );
};

export default InstructionPage;
