import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import '@luna-protocol/core/src/index.scss';

if (import.meta.env.MODE === 'development' && import.meta.env.VITE_API_MOCKS === 'true') {
  import('./utils/browser').then(async ({ worker }) => {
    await worker.start();
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
  });
} else {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
}
