export const getPayloadFromToken = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  const payload = JSON.parse(jsonPayload);
  return payload;
};

export const getCustomerIdFromToken = (token: string) => {
  const payload = getPayloadFromToken(token);

  return payload.sub;
};

export const getUserTypeFromToken = (token: string) => {
  const payload = getPayloadFromToken(token);

  return payload.user_type;
};
