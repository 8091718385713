import { useCallback } from 'react';
import { validStatuses } from '../constants';

const useCustomerStatusCheck = () => {
  const checkStatus = useCallback((status: string) => {
    return validStatuses.includes(status);
  }, []);

  return { checkStatus };
};

export default useCustomerStatusCheck;
