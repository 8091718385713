import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'create.title',
    defaultMessage: 'Create your Dealer account',
  },
  emailAddressLabel: {
    id: 'create.emailAddressLabel',
    defaultMessage: 'Email address',
  },
  passwordLabel: {
    id: 'create.passwordLabel',
    defaultMessage: 'Password',
  },
  passwordConfirmLabel: {
    id: 'create.passwordConfirmLabel',
    defaultMessage: 'Re-enter Password',
  },
  signUpButton: {
    id: 'create.signUpButton',
    defaultMessage: 'Sign up',
  },
  invalidEmailOrPassword: {
    id: 'create.invalidEmailOrPassword',
    defaultMessage: 'Invalid Email and/or Password. Please try again or contact support.',
  },
});

export default messages;
