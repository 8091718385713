/**
 * ButtonGroup Component
 *
 * A component to group buttons together with optional styling properties.
 *
 * @component
 *
 * @param {Object} props - The props for the ButtonGroup component.
 * @param {React.ReactNode} [children] - The buttons or elements to be grouped together.
 * @param {boolean} [fullWidth=false] - If true, the ButtonGroup will take full width.
 * @param {'left' | 'center' | 'right'} [align='center'] - Alignment of the button group within the container.
 * @param {boolean} [narrowWidth=false] - If true, the ButtonGroup will have a narrow width.
 *
 * @example
 * <ButtonGroup fullWidth align="center">
 *    <button>Button 1</button>
 *    <button>Button 2</button>
 * </ButtonGroup>
 */

import React, { Children } from 'react';
import './ButtonGroup.scss';

interface ButtonGroupProps {
  children?: React.ReactNode;
  fullWidth?: boolean;
  align?: 'left' | 'center' | 'right';
  narrowWidth?: boolean;
}

const ButtonGroup = ({ children, fullWidth = false, align = 'center', narrowWidth = false }: ButtonGroupProps) => (
  <div className={`ButtonGroup${fullWidth ? ' full-width' : ''} ${align} ${narrowWidth ? 'narrowWidth' : ''}`}>
    {children && Children.toArray(children).length > 0 ? children : null}
  </div>
);

export default ButtonGroup;
