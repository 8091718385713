import { useIntl } from 'react-intl';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Card from '@luna-protocol/core/src/components/Card/Card';
import CustomerName from '@luna-protocol/core/src/components/CustomerName/CustomerName';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';

import { AppContext } from '../../AppContext.tsx';
import { useGetSerialNumbers } from '../../queries/useGetSerialNumbers.ts';
import { usePutSerialNumbers } from '../../queries/usePutSerialNumbers.ts';
import { buildSerialNumberProducts, getValue } from './getProducts.tsx';
import ProductSummary from '../ProductSummary/ProductSummary.tsx';
import messages from './AmendDetails.messages.ts';
import { ProductItem } from '../../types.ts';
import './AmendDetails.scss';
import { RETRIEVE_SERIAL_NUMBERS } from '../../queries/constants.ts';
import { getSerialNumbers } from '../../queries/useGetSerialNumbers.ts';

const AmendDetails = () => {
  useAuth();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { putUpdatedProductSummary } = usePutSerialNumbers();
  const { loanApplicationID, customerName, setCustomerName, serialNumbers, setSerialNumbers } = useContext(AppContext);
  const { data } = useGetSerialNumbers(loanApplicationID);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data === undefined) return;
    if (customerName === undefined || customerName === '') {
      setCustomerName(data?.primary_customer_name);
    }
    if (serialNumbers === undefined) {
      setSerialNumbers(data);
    }
  }, [data, customerName, setCustomerName, serialNumbers, setSerialNumbers]);

  const onSubmit = (newProductList: ProductItem[]) => {
    const serialNumberProducts = buildSerialNumberProducts(
      newProductList.map(({ title, value, type }) => ({ title, value, type })),
    );
    const nonSerialNumberItems = newProductList.filter(
      item => !item.title.includes('Number') && !item.title.includes('Price'),
    );

    const requestBody = {
      loan_application_id: loanApplicationID || '',
      item_name: getValue(nonSerialNumberItems, 'Item').toString(),
      serial_numbers: [...serialNumberProducts],
    };

    putUpdatedProductSummary(requestBody, {
      onSuccess: async () => {
        queryClient.invalidateQueries(RETRIEVE_SERIAL_NUMBERS);

        if (loanApplicationID !== undefined) {
          const serialNumbersData = await getSerialNumbers(loanApplicationID);
          setSerialNumbers(serialNumbersData);
        }
        navigate(`/add_products`);
      },
      onError: context => {
        console.error('error', context);
      },
    });
  };

  return (
    <>
      <BackButton path="/prequalification" inverted />
      <Banner alternate>{formatMessage(messages.title)}</Banner>
      <CustomerName applicantName={customerName || ''} />
      <Body fullWidth>
        <div className="amend-details--container">
          <Card variant={'grey'}>
            <ProductSummary onSubmit={onSubmit} approvedAmount={data?.approved_amount} />
          </Card>
        </div>
      </Body>
    </>
  );
};

export default AmendDetails;
