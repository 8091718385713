import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { FormikErrors, FormikValues } from 'formik';

import Card from '@luna-protocol/core/src/components/Card/Card';
import Input from '@luna-protocol/core/src/components/Input/Input.tsx';
import SelectInput from '@luna-protocol/core/src/components/SelectInput/SelectInput.tsx';
import { stateCodeMapping } from '@luna-protocol/core/src/utils/constants/states.js';

import messages from './ReviewDeal.messages.ts';
import { ReviewDealValues } from './ReviewDeal.tsx';
import { Applicant } from '../../queries/useGetApplicationSummary.ts';

export const AdditionalInformation = ({
  handleChange,
  values,
  errors,
  applicant,
}: {
  handleChange: (e: ChangeEvent) => void;
  values: FormikValues;
  errors: FormikErrors<ReviewDealValues>;
  applicant?: Applicant;
}) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <h2>{formatMessage(messages.additionalInformation)}</h2>
      <Card>
        <div>
          <div className="space-between">
            <span>{formatMessage(messages.primaryUseOfCollateral)}</span>
            <span className="collateral">
              <span>
                <input type="radio" name="primaryUseOfCollateral" value="Personal" onChange={handleChange} />
                <label htmlFor="primaryUseOfCollateral">{formatMessage(messages.personal)}</label>
              </span>
              <span>
                <input type="radio" name="primaryUseOfCollateral" value="Business" onChange={handleChange} />
                <label htmlFor="primaryUseOfCollateral">{formatMessage(messages.business)}</label>
              </span>
              <span>
                <input type="radio" name="primaryUseOfCollateral" value="Agriculture" onChange={handleChange} />
                <label htmlFor="primaryUseOfCollateral">{formatMessage(messages.agriculture)}</label>
              </span>
            </span>
          </div>
          {errors?.primaryUseOfCollateral && (
            <div className="error-messaging" data-testid="error-messaging">
              {errors?.primaryUseOfCollateral}
            </div>
          )}
          <div>
            <p>{formatMessage(messages.optionsIncludedOnUnit)}</p>
            <div className="options">
              <span>
                <input type="checkbox" name="options" value="Sun/Moon Roof" onChange={handleChange} />
                <label htmlFor="sunMoonRoof">{formatMessage(messages.sunMoonRoof)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Air-conditioning" onChange={handleChange} />
                <label htmlFor="airConditioning">{formatMessage(messages.airConditioning)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Automatic transition" onChange={handleChange} />
                <label htmlFor="automaticTransition">{formatMessage(messages.automaticTransition)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Power steering" onChange={handleChange} />
                <label htmlFor="powerSteering">{formatMessage(messages.powerSteering)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Power door locks" onChange={handleChange} />
                <label htmlFor="powerDoorLocks">{formatMessage(messages.powerDoorLocks)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Power seats" onChange={handleChange} />
                <label htmlFor="powerSeats">{formatMessage(messages.powerSeats)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Power windows" onChange={handleChange} />
                <label htmlFor="powerWindows">{formatMessage(messages.powerWindows)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Tilt wheel" onChange={handleChange} />
                <label htmlFor="tiltWheel">{formatMessage(messages.tiltWheel)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Vinyl top" onChange={handleChange} />
                <label htmlFor="vinylTop">{formatMessage(messages.vinylTop)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Cassette" onChange={handleChange} />
                <label htmlFor="cassette">{formatMessage(messages.cassette)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Cruise control" onChange={handleChange} />
                <label htmlFor="cruiseControl">{formatMessage(messages.cruiseControl)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="AM/FM stereo" onChange={handleChange} />
                <label htmlFor="amFmStereo">{formatMessage(messages.amFmStereo)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="Compact disc player" onChange={handleChange} />
                <label htmlFor="compactDiscPlayer">{formatMessage(messages.compactDiscPlayer)}</label>
              </span>
              <span>
                <input type="checkbox" name="options" value="No options" onChange={handleChange} />
                <label htmlFor="noOptions">{formatMessage(messages.noOptions)}</label>
              </span>
            </div>
          </div>
          <div className="yesNo">
            <span>
              {formatMessage(messages.unitAddress, {
                address: `${applicant?.address_lines ? `${applicant.address_lines.join(',')}, ` : ''}${applicant?.city ? `${applicant.city}, ` : ''}${applicant?.county ? `${applicant.county}, ` : ''}${applicant?.state ? applicant.state : ''}`,
              })}
            </span>
            <span>
              <span>
                <input type="radio" name="unitAddress" value="yes" onChange={handleChange} />
                <label htmlFor="yes">{formatMessage(messages.yes)}</label>
              </span>
              <span>
                <input type="radio" name="unitAddress" value="no" onChange={handleChange} />
                <label htmlFor="no">{formatMessage(messages.no)}</label>
              </span>
            </span>
          </div>
          {errors?.unitAddress && (
            <div className="error-messaging" data-testid="error-messaging">
              {errors?.unitAddress}
            </div>
          )}
          {values.unitAddress === 'no' && (
            <div className="unitAddress">
              <Input
                placeholder={formatMessage(messages.streetAddress)}
                required
                label={formatMessage(messages.streetAddress)}
                name="unitStreetAddress"
                type="text"
                value={values.unitStreetAddress}
                onChange={handleChange}
                error={errors?.unitStreetAddress ? errors?.unitStreetAddress : undefined}
              />
              <Input
                placeholder={formatMessage(messages.city)}
                required
                label={formatMessage(messages.city)}
                name="unitCity"
                type="text"
                value={values.unitCity}
                onChange={handleChange}
                error={errors?.unitCity ? errors?.unitCity : undefined}
              />
              <Input
                placeholder={formatMessage(messages.county)}
                required
                label={formatMessage(messages.county)}
                name="unitCounty"
                type="text"
                value={values.unitCounty}
                onChange={handleChange}
                error={errors?.unitCounty ? errors?.unitCounty : undefined}
              />
              <SelectInput
                label={formatMessage(messages.state)}
                placeholder={formatMessage(messages.state)}
                required
                // @ts-expect-error Type '(e: ChangeEvent<Element>) => void' is not assignable to type '(newValue: string | SelectOption | undefined) => void'.
                onChange={handleChange}
                value={values.unitState}
                error={errors.unitState ? errors.unitState : undefined}
                name="unitState"
                options={stateCodeMapping}
              />
            </div>
          )}
          <div className="yesNo">
            <span>
              {formatMessage(messages.primaryAddress, {
                address: `${applicant?.address_lines ? `${applicant.address_lines.join(',')}, ` : ''}${applicant?.city ? `${applicant.city}, ` : ''}${applicant?.county ? `${applicant.county}, ` : ''}${applicant?.state ? applicant.state : ''}`,
              })}
            </span>
            <span>
              <span>
                <input type="radio" name="primaryAddress" value="yes" onChange={handleChange} />
                <label htmlFor="yes">{formatMessage(messages.yes)}</label>
              </span>
              <span>
                <input type="radio" name="primaryAddress" value="no" onChange={handleChange} />
                <label htmlFor="no">{formatMessage(messages.no)}</label>
              </span>
            </span>
          </div>
          {errors?.primaryAddress && (
            <div className="error-messaging" data-testid="error-messaging">
              {errors?.primaryAddress}
            </div>
          )}
          {values.primaryAddress === 'no' && (
            <div className="primaryAddress">
              <Input
                placeholder={formatMessage(messages.streetAddress)}
                required
                label={formatMessage(messages.streetAddress)}
                name="primaryStreetAddress"
                type="text"
                value={values.primaryStreetAddress}
                onChange={handleChange}
                error={errors?.primaryStreetAddress ? errors?.primaryStreetAddress : undefined}
              />
              <Input
                placeholder={formatMessage(messages.city)}
                required
                label={formatMessage(messages.city)}
                name="primaryCity"
                type="text"
                value={values.primaryCity}
                onChange={handleChange}
                error={errors?.primaryCity ? errors?.primaryCity : undefined}
              />
              <Input
                placeholder={formatMessage(messages.county)}
                required
                label={formatMessage(messages.county)}
                name="primaryCounty"
                type="text"
                value={values.primaryCounty}
                onChange={handleChange}
                error={errors?.primaryCounty ? errors?.primaryCounty : undefined}
              />
              <SelectInput
                label={formatMessage(messages.state)}
                placeholder={formatMessage(messages.state)}
                required
                // @ts-expect-error Type '(e: ChangeEvent<Element>) => void' is not assignable to type '(newValue: string | SelectOption | undefined) => void'.
                onChange={handleChange}
                value={values.primaryState}
                error={errors.primaryState ? errors.primaryState : undefined}
                name="primaryState"
                options={stateCodeMapping}
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
