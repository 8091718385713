import Complete from '@luna-protocol/core/src/components/Complete/Complete';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import isLoggedIn from '@luna-protocol/core/src/utils/isLoggedIn.ts';
import messages from './DealerComplete.messages.ts';

type DealerCompleteProps = {
  signed?: boolean;
};

const DealerComplete = ({ signed = false }: DealerCompleteProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const isUserLoggedIn = isLoggedIn();

  return (
    <>
      {!signed ? (
        <>
          <Complete
            title={formatMessage(messages.title)}
            body={[formatMessage(messages.body)]}
            buttonOnClick={() => {
              navigate('/prequalification');
              sessionStorage.clear();
            }}
            buttonLabel={formatMessage(messages.returnButton)}
          />
        </>
      ) : (
        <Complete
          title={formatMessage(messages.titleSigned)}
          body={[
            formatMessage(messages.bodySigned, {
              link: <a href="mailto:recsupport@aquafinance.com">{formatMessage(messages.email)}</a>,
            }),
          ]}
          buttonOnClick={() => {
            navigate('/prequalification');
            sessionStorage.clear();
          }}
          buttonLabel={isUserLoggedIn ? formatMessage(messages.returnButtonSigned) : undefined}
        />
      )}
    </>
  );
};

export default DealerComplete;
