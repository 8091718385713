import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import { ReactNode } from 'react';
import './Complete.scss';

interface CompleteProps {
  title: string;
  body: ReactNode[];
  buttonLabel?: string;
  buttonOnClick?: () => void;
}

const Complete = ({ title, body, buttonLabel, buttonOnClick }: CompleteProps) => {
  return (
    <>
      <Banner>{title}</Banner>
      <Body>
        {body?.map((paragraph, index) => (
          <p key={index} className="body--text">
            {paragraph}
          </p>
        ))}
        <LineBreak />
        {buttonLabel && buttonOnClick ? (
          <ButtonGroup align="left">
            <Button fullWidth={false} variant="secondary" onClick={buttonOnClick}>
              {buttonLabel}
            </Button>
          </ButtonGroup>
        ) : null}
      </Body>
    </>
  );
};

export default Complete;
