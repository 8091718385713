import axios from 'axios';
import { useQuery } from 'react-query';
import { PRODUCT_TYPES } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface ProductTypesRes {
  product_type: ProductType[];
}

export interface ProductType {
  productLine: string;
  productCode: string;
  productType: string;
  collateralType: string;
  minSellingPrice?: number;
  maxSellingPrice?: number;
  contractType: string;
}

const getProductTypes = async (state: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/product_types/${state}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get product types');
  }
  return resp.data as ProductTypesRes;
};

const useGetProductTypes = (state: string) => {
  return useQuery(PRODUCT_TYPES, async () => {
    return await getProductTypes(state);
  });
};
export { useGetProductTypes };
