import { useContext } from 'react';
import { AppContext } from '../../AppContext.tsx';
import { PostSerialResponse } from '../../queries/useGetSerialNumber.ts';

const useClearSessionData = () => {
  const { setFloorplanDetails } = useContext(AppContext);

  const clearSessionData = () => {
    // clear session data before opening a new application, in case we have old data in session
    sessionStorage.clear();
    // clear context data
    setFloorplanDetails(new Map<string, PostSerialResponse>());
  };

  return clearSessionData;
};

export default useClearSessionData;
