import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './Screen.scss';
import { NavLinkProp } from '../../types.ts';
// @ts-expect-error TS2307: Cannot find module probably because it's out of src directory.
import version from '../../../../VERSION';

interface ScreenProps {
  navigationLinks?: NavLinkProp[];
}

const Screen = ({ navigationLinks }: ScreenProps) => {
  const localPortal = () => {
    const port = window.location.port;

    switch (port) {
      case '5100':
        return 'Consumer Portal';
      case '5200':
        return 'Admin Portal';
      case '5300':
        return 'Dealer Portal';
    }
  };

  const portal = () => {
    const url = window.location.href;
    const isDealerPortal =
      url.includes('power-dealer.aquafinance') ||
      url.includes('pwr-dlr-stage.aquafinance') ||
      url.includes('5300') ||
      url.includes('dev-wf-aqua-dealer');
    const isConsumerPortal =
      url.includes('power.aquafinance') ||
      url.includes('pwr-stage.aquafinance') ||
      url.includes('5100') ||
      url.includes('dev-wf-aqua-consumer');

    if (isDealerPortal) {
      return 'dealer';
    }
    if (isConsumerPortal) {
      return 'consumer';
    }
    return;
  };

  return (
    <>
      <div className="screen-mode">
        {import.meta.env.MODE === 'development' && (
          <>
            <span>Development Mode</span>
            <span>{localPortal()}</span>
          </>
        )}
        {import.meta.env.MODE !== 'PRODUCTION' && import.meta.env.MODE !== 'test' && (
          <span>{`Version ${version}`}</span>
        )}
      </div>
      <Header navigationLinks={navigationLinks} portal={portal()} />
      <div className="Screen">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Screen;
