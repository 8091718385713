import { object, string, AnySchema, ref } from 'yup';
// @ts-expect-error  Cannot find module 'yup/lib/object' or its corresponding type declarations.
import { OptionalObjectSchema } from 'yup/lib/object';
import { CreateCredentials } from './CreateAccount';

export const createSchema: OptionalObjectSchema<Record<keyof CreateCredentials, AnySchema>> = object().shape({
  emailAddress: string().email('This is not a valid email, please re-enter').required(),
  password: string()
    .required('Password')
    .min(12, 'Password is too short - should be 12 chars minimum.')
    .matches(/[A-Z]/, 'Contain at least 1 uppercase character')
    .matches(/[a-z]/, 'Contain at least 1 lowercase character')
    .matches(/[a-z]/, 'Contain at least 1 number')
    .matches(/[[!@#$%^&*]/, 'Contain at least 1 special character'),
  passwordConfirm: string()
    .required('Both fields are required')
    .oneOf([ref('password')], 'Passwords must match'),
});
