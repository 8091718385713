import './OfferCard.scss';

type OfferCardProps = {
  img?: string;
  title: string;
  offer: string;
  offerDescription?: string;
  color?: 'grey' | 'orange';
};

const OfferCard = ({ img, title, color = 'orange', offer, offerDescription }: OfferCardProps) => {
  return (
    <div className="offer-card">
      <img className="offer-card--image" src={img} alt="brand logo" />
      <div className="offer-card--title">{title}</div>
      <div className="offer-card--description">
        <span className={`offer-card--description--title ${color && color}`}>{offer}</span>
        {offerDescription && <span className="offer-card--description--details">{offerDescription}</span>}
      </div>
    </div>
  );
};

export default OfferCard;
