import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../AppContext.tsx';
import confirmedTick from '@luna-protocol/core/src/assets/confirmedTick.svg';
import { useGetSerialNumber } from '../../queries/useGetSerialNumber.ts';
import messages from './VinLookup.messages.ts';
import './VinLookup.scss';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';

type VinLookupProps = {
  SerialNumber: string;
  UpdateSerialNumber: (NewSerialNumber: string) => void;
};

const VinLookup = ({ SerialNumber, UpdateSerialNumber }: VinLookupProps) => {
  const { formatMessage } = useIntl();
  const [vinConfirmed, setVinConfirmed] = useState(false);
  const { floorplanDetails, setFloorplanDetails, OEMType } = useContext(AppContext);
  const [vin, setVin] = useState(SerialNumber);
  const [isMissing, setIsMissing] = useState(false);

  const { data, refetch, isLoading } = useGetSerialNumber(vin);

  const updateVin = (e: ChangeEvent<HTMLInputElement>) => {
    setVin(e.target.value);
  };

  const confirmVin = () => {
    refetch();
  };

  useEffect(() => {
    if (data) {
      const updatedFP = floorplanDetails.set(vin, data);
      setFloorplanDetails(updatedFP);
      // only update the serial number if the vin is valid
      if (data?.floorplan_balance === 0) {
        setIsMissing(true);
        return;
      }
      UpdateSerialNumber(vin);
      setVinConfirmed(true);
    }
  }, [data]);

  useEffect(() => {
    if (floorplanDetails && floorplanDetails.has(SerialNumber)) {
      setVinConfirmed(true);
    }
  }, []);

  let financeEndDateLabel = formatMessage(messages.motorcycleFinanceEndDate);
  if (OEMType === 'brunswick') {
    financeEndDateLabel = formatMessage(messages.boatFinanceEndDate);
  }
  if (OEMType === 'ezgo') {
    financeEndDateLabel = formatMessage(messages.golfCartFinanceEndDate);
  }

  return (
    <>
      <div className="vin-lookup--container">
        <input
          className="text-input vin-lookup--input"
          type="text"
          value={vin}
          onChange={e => updateVin(e)}
          disabled={vinConfirmed}
        />
        {!vinConfirmed ? (
          isLoading ? (
            <p>{formatMessage(messages.loading)}</p>
          ) : (
            <button className="vin-lookup--confirm-button" onClick={confirmVin}>
              {formatMessage(messages.confirm)}
            </button>
          )
        ) : (
          <img className="vin-lookup--confirm" src={confirmedTick} alt="confirmed" />
        )}
      </div>
      {isMissing && <div className="vin-lookup--row">{formatMessage(messages.missing)}</div>}
      <div className="vin-lookup--row">
        <div className="vin-lookup--row--title">{formatMessage(messages.netFloorBalance)}</div>
        <div className="vin-lookup--row--value">
          {data?.floorplan_balance && USDollar.format(data?.floorplan_balance || 0)}
        </div>
      </div>
      <div className="vin-lookup--row">
        <div className="vin-lookup--row--title">{financeEndDateLabel}</div>
        <div className="vin-lookup--row--value">{data?.financing_end_date}</div>
      </div>
    </>
  );
};

export default VinLookup;
