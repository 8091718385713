import axios from 'axios';
import { useQuery } from 'react-query';
import { PRICING_STATUS } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface PricingStatus {
  status: string;
  amount: number;
}

const getPricingStatus = async (loanApplicationID: string): Promise<PricingStatus> => {
  const resp = await axios.get(`${getBaseApi()}/dealer/pricing_status/${loanApplicationID}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get pricing status');
  }
  if (resp.data.status === 'created') {
    throw new Error('Pricing has not started');
  } else if (resp.data.status === 'prequal-pending') {
    throw new Error('Pricing is in progress');
  }
  return resp.data as PricingStatus;
};

const useGetPricingStatus = (loanApplicationID: string | undefined) => {
  return useQuery<PricingStatus, Error, PricingStatus>(
    [PRICING_STATUS, loanApplicationID],
    async () => {
      if (loanApplicationID === undefined) {
        throw new Error('Loan application ID is undefined');
      }
      return await getPricingStatus(loanApplicationID);
    },
    {
      enabled: false, // don't fetch on mount
      cacheTime: 0,
      retry: 30,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** (attempt - 1) * 200 : 200, 5000), // exponential backoff
    },
  );
};

export { useGetPricingStatus };
