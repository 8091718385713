import { Form, Formik, FormikValues } from 'formik';
import { useContext, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { AppContext } from '../../AppContext';
import Button from '@luna-protocol/core/src/components/Button/Button';
import Input from '@luna-protocol/core/src/components/Input/Input';
import messages from './Accessories.message';

import './Accessories.scss';
import { AccessoryDetail, DealInformation } from '@luna-protocol/core/src/types.ts';

const AddAccessories = () => {
  const { formatMessage } = useIntl();
  const { deal, setDeal } = useContext(AppContext);

  const initialValues: AccessoryDetail = {
    name: '',
    price: 0,
    is_financeable: false,
  };

  const onSubmit = async (values: AccessoryDetail, { resetForm }: FormikValues) => {
    let updatedAccessories: AccessoryDetail[];
    const newAccessory = {
      name: values.name,
      price: Number(values.price),
      is_financeable: values.is_financeable,
    };

    if (deal?.accessories !== null && deal?.accessories !== undefined) {
      updatedAccessories = [...deal.accessories, newAccessory];
    } else {
      updatedAccessories = [newAccessory];
    }
    const updatedDeal = { ...deal, accessories: updatedAccessories } as DealInformation;
    setDeal(updatedDeal);
    resetForm();
  };

  const accessoriesSchema = Yup.object({
    name: Yup.string().required(),
    price: Yup.number().required(),
  });

  return (
    <div className="add-accessories">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={accessoriesSchema}>
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <div className="accessories--row">
              <Input
                className="accessories-input--name"
                label={formatMessage(messages.accessoriesLabel)}
                placeholder={formatMessage(messages.accessoriesPlaceholder)}
                name="name"
                onChange={handleChange}
                value={values.name}
                error={undefined}
              />
              <Input
                className="accessories-input--price"
                type="currency"
                name="price"
                value={values.price}
                currency
                onClick={(e: ChangeEvent<HTMLInputElement>) => e.target.select()}
                onChange={e => setFieldValue('price', Number(e.target.value))}
                placeholder={formatMessage(messages.priceLabel)}
                label={formatMessage(messages.priceLabel)}
                error={undefined}
              />
              <Button
                variant="secondary"
                className="button accessories-input--btn"
                disabled={!(values.name && values.price)}
                name={'add-accessory'}>
                {formatMessage(messages.buttonLabel)}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddAccessories;
