import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { LoginCredentials, LoginResponse } from '@luna-protocol/core/src/types';
import { getDealerIdFromToken } from '@luna-protocol/core/src/utils/getDealerIdFromToken';

const usePostLogin = () => {
  const mutation = useMutation(async (loginCredentials: LoginCredentials) => {
    const resp = await axios.post(`${getBaseApi()}/dealer/login`, JSON.stringify(loginCredentials), {
      headers: { 'Content-Type': 'application/json' },
    });

    if (resp.status !== 200) {
      throw new Error('Login failed');
    }

    // store token in local storage
    localStorage.setItem('user_token', resp.data.token);
    localStorage.setItem('dealer_id', getDealerIdFromToken(resp.data.token));
    return resp.data as LoginResponse;
  });

  return {
    login: mutation.mutate,
    ...mutation,
  };
};

export { usePostLogin };
