import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'retrievePrequalification.title',
    defaultMessage: 'Retrieve an application',
  },
  instruction: {
    id: 'retrievePrequalification.instruction',
    defaultMessage: 'Please enter the customer’s reference number below to retrieve an in-progress prequalification.',
  },
  inputLabel: {
    id: 'retrievePrequalification.inputLabel',
    defaultMessage: 'Reference number',
  },
  inputPlaceholder: {
    id: 'retrievePrequalification.inputPlaceholder',
    defaultMessage: 'Please enter',
  },
  submit: {
    id: 'retrievePrequalification.submit',
    defaultMessage: 'Retrieve details',
  },
  invalidReferenceNumber: {
    id: 'retrievePrequalification.invalidReferenceNumber',
    defaultMessage: 'Unable to find pre-qualification for this reference number. Please check and try again.',
  },
});

export default messages;
