import { defineMessages } from 'react-intl';

const messages = defineMessages({
  optional: {
    id: 'web.core.components.checkbox.optional',
    defaultMessage: 'optional',
  },
});

export default messages;
