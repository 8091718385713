import styled, { css, keyframes } from 'styled-components';

interface SpinnerProps {
  color?: 'white' | 'black';
  tablespinner?: boolean;
}

// adapted from generated code from https://loading.io/

export const Spinner = ({ color = 'white', tablespinner = false }: SpinnerProps) => {
  return (
    <Container className="container" role="status" tablespinner={tablespinner}>
      <Wheel data-testid="spinner-wheel" color={color}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Wheel>
    </Container>
  );
};

const Container = styled.div<SpinnerProps>(
  ({ tablespinner }) => css`
    width: 200px;
    height: 200px;
    display: flex;
    background: transparent;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    ${tablespinner && 'transform: translateY(-90px);'}
  `,
);

const wheelAnination = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Wheel = styled.div<SpinnerProps>(
  ({ color }) => css`
    position: relative;
    & div {
      left: 50%;
      top: 50%;
      box-sizing: content-box;
      position: absolute;
      animation: ${wheelAnination} linear 1s infinite;
      background: #ffffff;
      width: 5.5px;
      height: 13.75px;
      border-radius: 0px / 0px;
      transform-origin: 2.75px 72.875px;
      background: ${color};
    }

    & div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.9722222222222222s;
    }

    & div:nth-child(2) {
      transform: rotate(10deg);
      animation-delay: -0.9444444444444444s;
    }

    & div:nth-child(3) {
      transform: rotate(20deg);
      animation-delay: -0.9166666666666666s;
    }

    & div:nth-child(4) {
      transform: rotate(30deg);
      animation-delay: -0.8888888888888888s;
    }

    & div:nth-child(5) {
      transform: rotate(40deg);
      animation-delay: -0.8611111111111112s;
    }

    & div:nth-child(6) {
      transform: rotate(50deg);
      animation-delay: -0.8333333333333334s;
    }

    & div:nth-child(7) {
      transform: rotate(60deg);
      animation-delay: -0.8055555555555556s;
    }

    & div:nth-child(8) {
      transform: rotate(70deg);
      animation-delay: -0.7777777777777778s;
    }

    & div:nth-child(9) {
      transform: rotate(80deg);
      animation-delay: -0.75s;
    }

    & div:nth-child(10) {
      transform: rotate(90deg);
      animation-delay: -0.7222222222222222s;
    }

    & div:nth-child(11) {
      transform: rotate(100deg);
      animation-delay: -0.6944444444444444s;
    }

    & div:nth-child(12) {
      transform: rotate(110deg);
      animation-delay: -0.6666666666666666s;
    }

    & div:nth-child(13) {
      transform: rotate(120deg);
      animation-delay: -0.6388888888888888s;
    }

    & div:nth-child(14) {
      transform: rotate(130deg);
      animation-delay: -0.6111111111111112s;
    }

    & div:nth-child(15) {
      transform: rotate(140deg);
      animation-delay: -0.5833333333333334s;
    }

    & div:nth-child(16) {
      transform: rotate(150deg);
      animation-delay: -0.5555555555555556s;
    }

    & div:nth-child(17) {
      transform: rotate(160deg);
      animation-delay: -0.5277777777777778s;
    }

    & div:nth-child(18) {
      transform: rotate(170deg);
      animation-delay: -0.5s;
    }

    & div:nth-child(19) {
      transform: rotate(180deg);
      animation-delay: -0.4722222222222222s;
    }

    & div:nth-child(20) {
      transform: rotate(190deg);
      animation-delay: -0.4444444444444444s;
    }

    & div:nth-child(21) {
      transform: rotate(200deg);
      animation-delay: -0.4166666666666667s;
    }

    & div:nth-child(22) {
      transform: rotate(210deg);
      animation-delay: -0.3888888888888889s;
    }

    & div:nth-child(23) {
      transform: rotate(220deg);
      animation-delay: -0.3611111111111111s;
    }

    & div:nth-child(24) {
      transform: rotate(230deg);
      animation-delay: -0.3333333333333333s;
    }

    & div:nth-child(25) {
      transform: rotate(240deg);
      animation-delay: -0.3055555555555556s;
    }

    & div:nth-child(26) {
      transform: rotate(250deg);
      animation-delay: -0.2777777777777778s;
    }

    & div:nth-child(27) {
      transform: rotate(260deg);
      animation-delay: -0.25s;
    }

    & div:nth-child(28) {
      transform: rotate(270deg);
      animation-delay: -0.2222222222222222s;
    }

    & div:nth-child(29) {
      transform: rotate(280deg);
      animation-delay: -0.19444444444444445s;
    }

    & div:nth-child(30) {
      transform: rotate(290deg);
      animation-delay: -0.16666666666666666s;
    }

    & div:nth-child(31) {
      transform: rotate(300deg);
      animation-delay: -0.1388888888888889s;
    }

    & div:nth-child(32) {
      transform: rotate(310deg);
      animation-delay: -0.1111111111111111s;
    }

    & div:nth-child(33) {
      transform: rotate(320deg);
      animation-delay: -0.08333333333333333s;
    }

    & div:nth-child(34) {
      transform: rotate(330deg);
      animation-delay: -0.05555555555555555s;
    }

    & div:nth-child(35) {
      transform: rotate(340deg);
      animation-delay: -0.027777777777777776s;
    }

    & div:nth-child(36) {
      transform: rotate(350deg);
      animation-delay: 0s;
    }
  `,
);
