import { ProductItem } from '../../types';

const getSerialNumberItemTitle = (serialNumberType: string, valueType: string) => {
  switch (serialNumberType) {
    case 'hull-id':
      return `HIN ${valueType}`;
    case 'engine':
      return `Engine ${valueType}`;
    case 'trailer':
      return `Trailer ${valueType}`;
    case '':
      return `${valueType}`;
    default:
      return `VIN ${valueType}`;
  }
};

const buildProductUpdate = (array: ProductItem[], type: string) => {
  const serialNumberItem = {
    serial_number_type: type.split('_')[0],
    serial_number: '',
    collateral_id: '',
    product_price: 0.0,
    seller_purchase_price: 0.0,
    condition: '',
    year: 0,
    make: '',
    model: '',
    color: '',
    nada_msrp_invoice: 0.0,
  };

  array.map((item: ProductItem) => {
    if (item.type?.includes(type) && item.title.includes('Number')) {
      serialNumberItem.serial_number = item.value.toString();
    }
    if (item.type?.includes(type) && item.title.includes('Price')) {
      serialNumberItem.product_price = Number(item.value.toString());
    }
    if (item.type?.includes(type) && item.title.includes('Condition')) {
      serialNumberItem.condition = item.value.toString().toLowerCase();
    }
    if (item.type?.includes(type) && item.title.includes('NADA')) {
      serialNumberItem.seller_purchase_price = Number(item.value.toString());
      serialNumberItem.nada_msrp_invoice = Number(item.value.toString());
    }
    if (item.type?.includes(type) && item.title.includes('Invoice')) {
      serialNumberItem.seller_purchase_price = Number(item.value.toString());
      serialNumberItem.nada_msrp_invoice = Number(item.value.toString());
    }
    if (item.type?.includes(type) && item.title.includes('Year')) {
      serialNumberItem.year = Number(item.value);
    }
    if (item.type?.includes(type) && item.title.includes('Make')) {
      serialNumberItem.make = item.value.toString();
    }
    if (item.type?.includes(type) && item.title.includes('Model')) {
      serialNumberItem.model = item.value.toString();
    }
    if (item.type?.includes(type) && item.title.includes('Color')) {
      serialNumberItem.color = item.value.toString();
    }
    if (item.type?.includes(type) && item.title.includes('Collateral ID')) {
      serialNumberItem.collateral_id = item.value.toString();
    }
  });
  if (serialNumberItem.serial_number) return serialNumberItem;
  return serialNumberItem;
};

const buildSerialNumberProducts = (array: ProductItem[]) => {
  if (array === undefined || array.length === 0) return [];
  const updatedSerialNumberItems = array
    .map(e => e.type)
    .filter((e, i, arr) => e !== undefined && arr.indexOf(e) === i)
    .map(type => buildProductUpdate(array, type || ''));
  return updatedSerialNumberItems.filter(item => item.serial_number != '');
};

const getValue = (array: { title: string; value: string | number }[], title: string) => {
  const targetItem = array.filter((item: { title: string; value: string | number }) => item.title === title);
  if (targetItem[0] === undefined) return '';
  return targetItem[0].value;
};

export { buildSerialNumberProducts, getValue, getSerialNumberItemTitle };
