import axios from 'axios';
import { useQuery } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { SERIAL_NUMBER } from './constants';

export interface PostSerialResponse {
  floorplan_balance: number;
  financing_end_date: string;
}

const getSerial = async (serial_number: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/serial_number/${serial_number}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });

  if (resp.status !== 200) throw new Error('failed to get serial number');

  return resp.data as PostSerialResponse;
};

const useGetSerialNumber = (serial_number: string) => {
  return useQuery(
    [SERIAL_NUMBER, serial_number],
    async () => {
      return await getSerial(serial_number);
    },
    {
      enabled: false, // don't run on mount
    },
  );
};

export { useGetSerialNumber };
