import axios from 'axios';
import { useQuery } from 'react-query';
import { DEALER_ID } from './constants';

const getDealerIdByLoanApplicationId = async (loanApplicationId: string) => {
  const resp = await axios.get(`/dealer/${loanApplicationId}/dealer`);

  if (resp.status !== 200) {
    throw new Error('Failed to get dealer ID by loan application ID');
  }

  return resp.data.dealer_id as string;
};

const useGetDealerIdByLoanApplicationId = (loanApplicationId: string) => {
  return useQuery(
    [DEALER_ID, loanApplicationId],
    () => {
      return getDealerIdByLoanApplicationId(loanApplicationId);
    },
    {
      enabled: !!loanApplicationId,
      retry: false,
    },
  );
};

export { useGetDealerIdByLoanApplicationId };
