import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'newLead.title',
    defaultMessage: 'Enter customer email and unit details',
  },
  emailLabel: {
    id: 'newLead.email',
    defaultMessage: 'Email address',
  },
  emailConfirmation: {
    id: 'newLead.emailConfirmation',
    defaultMessage: 'Confirm email address is correct',
  },
  emailPlaceholder: {
    id: 'newLead.emailPlaceholder',
    defaultMessage: 'Please enter',
  },
  submitEmail: {
    id: 'newLead.submit',
    defaultMessage: 'Send Pre-qualification',
  },
  submitInFlow: {
    id: 'newLead.submitInFlow',
    defaultMessage: 'Continue to pre-qualification',
  },
  checkboxLabel: {
    id: 'newLead.checkboxLabel',
    defaultMessage: 'Tick here if you would like to email the pre-qualification application form to the consumer',
  },
  productInformation: {
    id: 'newLead.productInformation',
    defaultMessage: 'Product information',
    fields: {
      make: {
        id: 'newLead.productInformation.make',
        defaultMessage: 'Make',
      },
      model: {
        id: 'newLead.productInformation.model',
        defaultMessage: 'Model',
      },
      name: {
        id: 'newLead.productInformation.name',
        defaultMessage: 'Name',
      },
      selling_price: {
        id: 'newLead.productInformation.selling_price',
        defaultMessage: 'Requested Amount',
      },
      purchase_price: {
        id: 'newLead.productInformation.purchase_price',
        defaultMessage: 'Purchase Price',
      },
      purchase_price_nada: {
        id: 'newLead.productInformation.purchase_price_nada',
        defaultMessage: 'NADA Price',
      },
      purchase_price_invoice: {
        id: 'newLead.productInformation.purchase_price_invoice',
        defaultMessage: 'Invoice Price',
      },
      year: {
        id: 'newLead.productInformation.year',
        defaultMessage: 'Year',
      },
      sellingPriceDisclaimer: {
        id: 'newLead.productInformation.sellingPriceDisclaimer',
        defaultMessage: 'Selling price + tax, title, and license',
      },
      condition: {
        id: 'newLead.productInformation.condition',
        defaultMessage: 'Condition',
      },
      serial_number_HIN: {
        id: 'newLead.productInformation.serial_number_HIN',
        defaultMessage: 'HIN',
      },
      serial_number_VIN: {
        id: 'newLead.productInformation.serial_number_VIN',
        defaultMessage: 'VIN',
      },
      serial_number: {
        id: 'newLead.productInformation.serial_number',
        defaultMessage: 'Serial Number',
      },
      asset_class: {
        id: 'newLead.productInformation.asset_class',
        defaultMessage: 'Asset Class',
      },
      colour: {
        ktm: {
          id: 'newLead.productInformation.colour.ktm',
          defaultMessage: 'MSRP/NADA',
        },
        brunswick: {
          id: 'newLead.productInformation.colour.brunswick',
          defaultMessage: 'Invoice/NADA',
        },
        ezgo: {
          id: 'newLead.productInformation.colour.ezgo',
          defaultMessage: 'MSRP/NADA',
        },
      },
      addAnother: {
        id: 'newLead.productInformation.addAnother',
        defaultMessage: 'Add another',
      },
      addEngine: {
        id: 'newLead.productInformation.addEngine',
        defaultMessage: 'Add Engine',
      },
      addTrailer: {
        id: 'newLead.productInformation.addTrailer',
        defaultMessage: 'Add Trailer',
      },
      remove: {
        id: 'newLead.productInformation.remove',
        defaultMessage: 'Remove',
      },
    },
  },
  customerInformation: {
    id: 'newLead.customerInformation',
    defaultMessage: 'Customer information',
  },
  itemTitle: {
    id: 'newLead.itemTitle',
    defaultMessage: 'Item',
  },
  paperApplicationTitle: {
    id: 'newLead.paperApplicationTitle',
    defaultMessage: 'Paper application',
  },
  paperApplicationDescription: {
    id: 'newLead.paperApplicationDescription',
    defaultMessage: 'Did your customer complete a paper application in your dealership?',
  },
  successMessage: {
    id: 'newLead.successMessage',
    defaultMessage: 'Email sent successfully',
  },
  errorMessage: {
    id: 'newLead.errorMessage',
    defaultMessage: 'Error sending email',
  },
  trailer: {
    id: 'newLead.trailer',
    defaultMessage: 'Trailer',
  },
  engine: {
    id: 'newLead.engine',
    defaultMessage: 'Engine',
  },
});

export default messages;
