import { defineMessages } from 'react-intl';

const messages = defineMessages({
  processing: {
    id: 'processing.processing',
    defaultMessage: 'This should only take a moment...',
  },
});

export default messages;
