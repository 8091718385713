import { Field } from 'formik';
import './RadioGroup.scss';

type RadioButton = {
  label: string;
  value: string;
};

type RadioGroupProps = {
  name: string;
  buttons: RadioButton[];
  noWrap?: boolean;
};

const RadioGroup = ({ buttons, name, noWrap = false }: RadioGroupProps) => {
  return (
    <div className={`radio-group ${noWrap ? 'no-wrap' : ''}`}>
      {buttons.map((button, index) => (
        <label key={index}>
          <Field
            className={`${button.label}-radio condition-radio`}
            type="radio"
            name={name}
            value={button.value}
            data-testid={`${name}-radio-${button.value}`}
          />
          <span className="radio-label">{`${button.label} `}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
