import './NavigationLink.scss';

interface NavigationLinkProps {
  label: string;
  path: string;
  className?: string;
}

const NavigationLink = ({ label, path, className }: NavigationLinkProps) => {
  const active = window.location.pathname === path;
  return (
    <a href={path} aria-label={`${label} link`} className={`link ${active ? 'active' : ''} ${className}`}>
      {label}
    </a>
  );
};

export default NavigationLink;
