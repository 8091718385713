import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Form, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { usePostCreate } from '../../queries/usePostCreate.ts';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import Input from '@luna-protocol/core/src/components/Input/Input';
import isLoggedIn from '@luna-protocol/core/src/utils/isLoggedIn.ts';
import { useEffect, useState } from 'react';
import messages from './CreateAccount.messages.ts';
import { createSchema } from './CreateAccountSchema.ts';

export type CreateCredentials = {
  emailAddress: string;
  password: string;
  passwordConfirm?: string;
};

const Create = () => {
  const { formatMessage } = useIntl();
  const { create, isError: errorSubmitting } = usePostCreate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const location = useLocation();
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (!isLoggedIn()) {
      // if the user is not logged in, redirect to OTP page to grab token and then redirect back to this page
      navigate('/email_verification', {
        state: { emailAddress: email, to: location.pathname + location.search, autoSend: true },
      });
    }
  }, []);

  const initialValues: CreateCredentials = {
    emailAddress: email || '',
    password: '',
    passwordConfirm: '',
  };

  const onSubmit = (values: CreateCredentials) => {
    setPending(true);
    create(values, {
      onSuccess: () => {
        navigate('/prequalification');
      },
      onError: context => {
        setPending(false);
        console.error('error', context);
      },
    });
  };

  return (
    <>
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={createSchema} enableReinitialize>
          {({ values, errors, handleBlur, touched, handleSubmit, handleChange }) => (
            <Form>
              <Input
                label={formatMessage(messages.emailAddressLabel)}
                placeholder={formatMessage(messages.emailAddressLabel)}
                name="emailAddress"
                onChange={handleChange}
                value={values.emailAddress}
                error={!(values.emailAddress.length < 1) && errors.emailAddress ? errors.emailAddress : undefined}
                disabled={!!email}
              />
              <Input
                label={formatMessage(messages.passwordLabel)}
                placeholder={formatMessage(messages.passwordLabel)}
                name="password"
                onChange={handleChange}
                value={values.password}
                error={!!touched.password && errors.password ? errors.password : undefined}
                onBlur={handleBlur}
                type="password"
              />
              <Input
                label={formatMessage(messages.passwordConfirmLabel)}
                placeholder={formatMessage(messages.passwordLabel)}
                name="passwordConfirm"
                onChange={handleChange}
                value={values.passwordConfirm}
                error={!!touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : undefined}
                onBlur={handleBlur}
                type="password"
              />
              {errorSubmitting && <p className="error">{formatMessage(messages.invalidEmailOrPassword)}</p>}
              <Button type="submit" variant="secondary" onClick={handleSubmit} pending={pending}>
                {formatMessage(messages.signUpButton)}
              </Button>
            </Form>
          )}
        </Formik>
      </Body>
    </>
  );
};

export default Create;
