const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    return null;
  }
};

// isLoggedIn checks the token exists AND is not expired
const isLoggedIn = () => {
  const token = localStorage.getItem('user_token');

  if (!token) return false;

  const decoded = decodeJWT(token);

  if (!decoded || !decoded.exp) return false;

  const currentEpochInSeconds = Math.floor(Date.now() / 1000);

  return decoded.exp > currentEpochInSeconds;
};

export default isLoggedIn;
