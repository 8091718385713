import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'verification.title',
    defaultMessage: 'Confirm email address',
  },
  body: {
    id: 'verification.body',
    defaultMessage: 'A 6-digit code has been sent to:',
  },
  label: {
    id: 'verification.label',
    defaultMessage: 'Enter the 6 digit code below',
  },
  resend: {
    id: 'verification.resend',
    defaultMessage: 'If the customer did not receive a code, please click {method} to resend the code via email.',
    methodText: {
      id: 'verification.resend.method',
      defaultMessage: 'here',
    },
  },
  submit: {
    id: 'verification.submit',
    defaultMessage: 'Continue',
  },
  clear: {
    id: 'verification.clear',
    defaultMessage: 'Clear Form',
  },
  error: {
    id: 'verification.error',
    defaultMessage: 'Invalid Code, please try again or request a new code.',
  },
  noEmailAddress: {
    id: 'verification.noEmailAddress',
    defaultMessage: 'your email address',
  },
  emailResent: {
    id: 'verification.emailResent',
    defaultMessage: 'Verification code sent',
  },
});

export default messages;
