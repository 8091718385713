import { useEffect, useState } from 'react';
import { getDealerIdFromToken } from '@luna-protocol/core/src/utils/getDealerIdFromToken.ts';

export const useDealerID = () => {
  const [dealerId, setDealerId] = useState<string | null>(null);

  const handleStorageChange = () => {
    const updatedToken = localStorage.getItem('user_token');
    if (updatedToken) {
      setDealerId(getDealerIdFromToken(updatedToken));
    } else {
      setDealerId(null);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('user_token');
    if (token) {
      setDealerId(getDealerIdFromToken(token));
    }

    window.addEventListener('storage', handleStorageChange);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return dealerId;
};
