import axios from 'axios';
import { useQuery } from 'react-query';
import { MAKE_MODELS } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface MakeModelsRes {
  make_models: MakeModel[];
}

export interface MakeModel {
  make: string;
  model: string;
  oemName: string;
  year: number;
  msrp: number;
}

const getMakeModels = async () => {
  const resp = await axios.get(`${getBaseApi()}/dealer/make_models`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get make models');
  }
  return resp.data as MakeModelsRes;
};

const useGetMakeModels = () => {
  return useQuery(MAKE_MODELS, async () => {
    return await getMakeModels();
  });
};
export { useGetMakeModels };
