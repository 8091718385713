import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { CreateCredentials } from '../components/CreateAccount/CreateAccount';

export type LoginResponse = {
  user_id: string;
};

const usePostCreate = () => {
  const mutation = useMutation((createCredentials: CreateCredentials) => {
    const { emailAddress, password } = createCredentials;
    return axios.post(
      `${getBaseApi()}/dealer/invite`,
      JSON.stringify({
        email: emailAddress,
        password,
      }),
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
      },
    );
  });

  return {
    create: mutation.mutate,
    ...mutation,
  };
};

export { usePostCreate };
