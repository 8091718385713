import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext.tsx';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';

const UpdateBranding = () => {
  const { setOEMType } = useContext(AppContext);
  const { data } = useGetDealerBranding(localStorage.getItem('dealer_id') || '');

  useEffect(() => {
    if (data && data.oem_type) {
      setOEMType(data.oem_type);
    }
  }, [data]);

  return <></>;
};

export default UpdateBranding;
