import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'signDocs.title',
    defaultMessage: 'Please sign the finance documents',
  },
  detail1: {
    id: 'signDocs.detail1',
    defaultMessage:
      'Click the button below to review the contract documents and provide your digital signature. A copy of the contract documents will be emailed to you.',
  },
  button: {
    id: 'signDocs.button',
    defaultMessage: 'Review contract documents',
  },
  returnButton: {
    id: 'signDocs.returnButton',
    defaultMessage: 'Return to home',
  },
});

export default messages;
