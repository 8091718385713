import axios from 'axios';
import { useQuery } from 'react-query';
import { FLOW_TEMPLATE } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { FlowTemplateData } from '@luna-protocol/core/src/types';

const getFlowTemplateById = async (flow_template_id: string): Promise<FlowTemplateData> => {
  const result = await axios.get(`${getBaseApi()}/dealer/flow-templates/${flow_template_id}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });

  if (result.status !== 200) {
    throw new Error('Failed to get flow template');
  }

  return result.data.flow_template as FlowTemplateData;
};
const useGetFlowTemplateById = (flow_template_id?: string) => {
  return useQuery(
    [FLOW_TEMPLATE, flow_template_id],
    async () => {
      if (!flow_template_id) {
        throw new Error('Flow template ID is undefined');
      }
      return getFlowTemplateById(flow_template_id);
    },
    { enabled: !!flow_template_id, retry: true },
  );
};

export { useGetFlowTemplateById };
