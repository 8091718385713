import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { ProductUpdateReq } from '../types';

const usePutSerialNumbers = () => {
  const mutation = useMutation((newProductSummary: ProductUpdateReq) => {
    const { loan_application_id, ...updates } = newProductSummary;
    return axios.put(`${getBaseApi()}/dealer/application/${loan_application_id}`, JSON.stringify(updates), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    putUpdatedProductSummary: mutation.mutate,
    ...mutation,
  };
};

export { usePutSerialNumbers };
