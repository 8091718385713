import { useIntl } from 'react-intl';
import messages from './Modal.messages';
import './Modal.scss';
import { useEffect } from 'react';

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  title?: string;
  isOpen?: boolean;
  showCloseButton?: boolean;
  centeredTitle?: boolean;
  centeredBody?: boolean;
  customButtons?: React.ReactNode;
  maxHeight?: boolean;
}

const Modal = ({
  children,
  onClose,
  title,
  isOpen = true,
  showCloseButton = true,
  centeredTitle = false,
  centeredBody = false,
  customButtons = false,
  maxHeight = false,
}: ModalProps) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <div className={`modal ${isOpen ? 'isOpen' : ''}`} role="dialog">
      <div className="modal-overlay" onClick={() => onClose()} />
      <div className={`modal-container ${maxHeight ? 'max-height' : ''}`} onClick={evt => evt.stopPropagation()}>
        <div className="modal-container--content">
          <div className="modal-header">
            <h2 className={`modal-title ${centeredTitle && 'centered-title'}`}>{title}</h2>
          </div>
          <div className={`modal-body ${centeredBody ? 'centered' : ''}`}>{children}</div>
          <div className="modal-footer">
            {showCloseButton && (
              <button className="button modal-close secondary" onClick={onClose} data-testid="modal-close-button">
                {formatMessage(messages.close)}
              </button>
            )}
            {customButtons && customButtons}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
