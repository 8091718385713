import { defineMessages } from 'react-intl';

const coreMessages = defineMessages({
  success: {
    id: 'core.success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'core.error',
    defaultMessage: 'Error',
  },
});

export default coreMessages;
