import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import { Spinner } from '@luna-protocol/core/src/components/Spinner/Spinner';

import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import { AppContext } from '../../AppContext.tsx';
import { useGetPricingStatus } from '../../queries/useGetPricingStatus.ts';
import { useStartPricing } from '../../queries/useStartPricing.ts';
import messages from './Processing.messages.ts';
import './Processing.scss';

const Processing = () => {
  const { formatMessage } = useIntl();
  const { loanApplicationID, setPrequalAmount, applicationSummaryDetails, setApplicationSummaryDetails } =
    useContext(AppContext);
  const { startPricing, isIdle: readyToStartPricing, isSuccess, isError } = useStartPricing();
  const { data: pricingData, refetch: refetchStatus, isSuccess: hasData } = useGetPricingStatus(loanApplicationID);

  const navigate = useNavigate();

  useEffect(() => {
    if (readyToStartPricing && loanApplicationID !== undefined) {
      startPricing(loanApplicationID);
    }
  }, [loanApplicationID, readyToStartPricing]); //this is a one time call

  useEffect(() => {
    // ensure pricingData is only called after pricing has begun
    if (isSuccess || isError) {
      refetchStatus();
    }

    if (hasData) {
      if (pricingData.status === 'error') {
        navigate('/pricing_error');
        return;
      }
      setPrequalAmount(USDollar.format(pricingData.amount));
      if (applicationSummaryDetails) {
        applicationSummaryDetails.approved_amount = pricingData.amount;
        setApplicationSummaryDetails(applicationSummaryDetails);
      }
      // commented out because totals.approved_amount is not used anywhere
      //if (totals) {
      //totals.approved_amount = pricingData.amount;
      //setTotals(totals);
      //}
      navigate('/select_program');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingData, navigate, refetchStatus, isSuccess, isError, hasData]);

  return (
    <>
      <BackButton path="/add_products" inverted />
      <Banner>
        <Spinner />
        <LineBreak />
        <p className="processing-message">{formatMessage(messages.processing)}</p>
      </Banner>
    </>
  );
};

export default Processing;
