import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'pricing_error.title',
    defaultMessage: 'Sorry, there was a problem retrieving the credit decision',
  },
  detail1: {
    id: 'pricing_error.detail1',
    defaultMessage: 'Unfortunately there was an error with the credit decisioning process. Please try again later.',
  },
  continue: {
    id: 'pricing_error.continue',
    defaultMessage: 'Return to pre-qualification list',
  },
});

export default messages;
