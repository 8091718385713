import axios from 'axios';
import { useQuery } from 'react-query';
import { RETRIEVE_PREQUALIFICATION } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export type RetrivalResponse = {
  loan_application_id?: string;
  error?: string;
};

const getPrequalificationUsingRetrival = async (referenceNumber: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/application/reference/${referenceNumber}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get prequalification');
  }
  return resp.data as RetrivalResponse;
};

const useGetPrequalificationUsingRetrival = (referenceNumber: string | undefined) => {
  return useQuery(
    [RETRIEVE_PREQUALIFICATION, referenceNumber],
    () => {
      if (referenceNumber === undefined) {
        throw new Error('referenceNumber is undefined');
      }
      return getPrequalificationUsingRetrival(referenceNumber);
    },
    { enabled: true },
  );
};
export { useGetPrequalificationUsingRetrival };
