import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Form, Formik } from 'formik';
import 'react-tooltip/dist/react-tooltip.css';

import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import Input from '@luna-protocol/core/src/components/Input/Input';
import aquaLogo from '@luna-protocol/core/src/assets/formLogo.png';

import Button from '@luna-protocol/core/src/components/Button/Button';
import Body from '@luna-protocol/core/src/components/Body/Body';
import SelectInput from '@luna-protocol/core/src/components/SelectInput/SelectInput';
import { stateCodeMapping } from '@luna-protocol/core/src/utils/constants/states.js';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak.js';

import { applicationSchema } from './applicationSchema';
import { PostDealerApplicationRequest, usePostDealerApplication } from '../../queries/usePostDealerApplication.js';
import { DealerApplicationFormValues } from '../../types.js';
import messages from './ApplicationForm.messages';
import './ApplicationForm.scss';
import { useState } from 'react';

const ApplicationForm = () => {
  const { formatMessage } = useIntl();
  const { submitApplication } = usePostDealerApplication();
  const [pending, setPending] = useState(false);

  const initialValues: DealerApplicationFormValues = {
    dealerShipName: '',
    dealerShipAddress: '',
    city: '',
    state: '',
    zipCode: '',
    dealershipEmail: '',
    dealershipPhoneNumber: '',
    dealerFirstName: '',
    dealerLastName: '',
    dealerAddress: '',
    dealerCity: '',
    dealerState: '',
    dealerZipCode: '',
    dealerEmailAddress: '',
    dealerCellPhoneNumber: '',
  };

  const onSubmit = async (values: DealerApplicationFormValues) => {
    setPending(true);
    const mappedValues: PostDealerApplicationRequest = {
      dealer: {
        address: {
          address_lines: [values.dealerShipAddress],
          city: values.city,
          state: values.state,
          zip_code: values.zipCode,
        },
        cell_phone_number: values.dealershipPhoneNumber,
        email: values.dealershipEmail,
        name: values.dealerShipName,
      },
      lead_user: {
        address: {
          address_lines: [values.dealerAddress],
          city: values.dealerCity,
          state: values.dealerState,
          zip_code: values.dealerZipCode,
        },
        cell_phone_number: values.dealerCellPhoneNumber,
        email: values.dealerEmailAddress,
        first_name: values.dealerFirstName,
        last_name: values.dealerLastName,
      },
    };

    submitApplication(mappedValues, {
      onSuccess: data => {
        console.log(data);
        // navigate???
      },
      onError: error => {
        setPending(false);
        console.log(error);
      },
    });
  };

  return (
    <>
      <Body fullWidth>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={applicationSchema}
          enableReinitialize>
          {({ values, errors, handleBlur, touched, handleSubmit, handleChange }) => (
            <Form>
              <div className="form-header">
                <h2>{formatMessage(messages.title)}</h2>
                <div className="aqua-logo">
                  {formatMessage(messages.slogan)}
                  <img src={aquaLogo} alt="logo" />
                </div>
              </div>
              <p>{formatMessage(messages.subtitle)}</p>
              <p className="notice">{formatMessage(messages.notice)}</p>
              <fieldset className="fieldset">
                <h3>{formatMessage(messages.dealershipInformation)}</h3>
                <Input
                  label={formatMessage(messages.dealerShipName)}
                  placeholder={formatMessage(messages.dealerShipName)}
                  name="dealerShipName"
                  onChange={handleChange}
                  value={values.dealerShipName}
                  error={!!touched.dealerShipName && errors.dealerShipName ? errors.dealerShipName : undefined}
                  onBlur={handleBlur}
                />
                <LineBreak />
                <Input
                  label={formatMessage(messages.dealerShipAddress)}
                  placeholder={formatMessage(messages.dealerShipAddress)}
                  name="dealerShipAddress"
                  onChange={handleChange}
                  value={values.dealerShipAddress}
                  error={errors.dealerShipAddress && !!touched.dealerShipAddress ? errors.dealerShipAddress : undefined}
                />
                <Input
                  label={formatMessage(messages.city)}
                  placeholder={formatMessage(messages.city)}
                  onChange={handleChange}
                  value={values.city}
                  error={errors.city && !!touched.city ? errors.city : undefined}
                  name="city"
                  disabled={values.state === ''}
                />
                <SelectInput
                  label={formatMessage(messages.state)}
                  placeholder={formatMessage(messages.state)}
                  onChange={handleChange}
                  value={values.state}
                  error={errors.state && !!touched.state ? errors.state : undefined}
                  name="state"
                  options={stateCodeMapping}
                />
                <Input
                  label={formatMessage(messages.zipCode)}
                  placeholder={formatMessage(messages.zipCode)}
                  name="zipCode"
                  onChange={handleChange}
                  value={values.zipCode}
                  error={errors.zipCode && !!touched.zipCode ? errors.zipCode : undefined}
                />
                <Input
                  label={formatMessage(messages.dealershipEmail)}
                  placeholder={formatMessage(messages.dealershipEmail)}
                  name="dealershipEmail"
                  onChange={handleChange}
                  value={values.dealershipEmail}
                  error={errors.dealershipEmail && !!touched.dealershipEmail ? errors.dealershipEmail : undefined}
                />
                <Input
                  label={formatMessage(messages.dealershipPhoneNumber)}
                  placeholder={formatMessage(messages.dealershipPhoneNumber)}
                  name="dealershipPhoneNumber"
                  type={'tel'}
                  onChange={handleChange}
                  value={values.dealershipPhoneNumber}
                  error={
                    errors.dealershipPhoneNumber && !!touched.dealershipPhoneNumber
                      ? errors.dealershipPhoneNumber
                      : undefined
                  }
                />
              </fieldset>
              <fieldset className="fieldset">
                <h3>{formatMessage(messages.dealerInformation)}</h3>
                <Input
                  label={formatMessage(messages.dealerFirstName)}
                  placeholder={formatMessage(messages.dealerFirstName)}
                  name="dealerFirstName"
                  onChange={handleChange}
                  value={values.dealerFirstName}
                  error={!!touched.dealerFirstName && errors.dealerFirstName ? errors.dealerFirstName : undefined}
                  onBlur={handleBlur}
                />
                <Input
                  label={formatMessage(messages.dealerLastName)}
                  placeholder={formatMessage(messages.dealerLastName)}
                  name="dealerLastName"
                  onChange={handleChange}
                  value={values.dealerLastName}
                  error={!!touched.dealerLastName && errors.dealerLastName ? errors.dealerLastName : undefined}
                  onBlur={handleBlur}
                />
                <Input
                  label={formatMessage(messages.dealerAddress)}
                  placeholder={formatMessage(messages.dealerAddress)}
                  name="dealerAddress"
                  onChange={handleChange}
                  value={values.dealerAddress}
                  error={errors.dealerAddress && !!touched.dealerAddress ? errors.dealerAddress : undefined}
                />
                <Input
                  label={formatMessage(messages.dealerCity)}
                  placeholder={formatMessage(messages.dealerCity)}
                  onChange={handleChange}
                  value={values.dealerCity}
                  error={errors.dealerCity && !!touched.dealerCity ? errors.dealerCity : undefined}
                  name="dealerCity"
                  disabled={values.dealerState === ''}
                />
                <SelectInput
                  label={formatMessage(messages.dealerState)}
                  placeholder={formatMessage(messages.dealerState)}
                  onChange={handleChange}
                  value={values.dealerState}
                  error={errors.dealerState && !!touched.dealerState ? errors.dealerState : undefined}
                  name="dealerState"
                  options={stateCodeMapping}
                />
                <Input
                  label={formatMessage(messages.dealerZipCode)}
                  placeholder={formatMessage(messages.dealerZipCode)}
                  name="dealerZipCode"
                  onChange={handleChange}
                  value={values.dealerZipCode}
                  error={errors.dealerZipCode && !!touched.dealerZipCode ? errors.dealerZipCode : undefined}
                />
                <Input
                  label={formatMessage(messages.dealerEmailAddress)}
                  placeholder={formatMessage(messages.dealerEmailAddress)}
                  name="dealerEmailAddress"
                  onChange={handleChange}
                  value={values.dealerEmailAddress}
                  error={
                    errors.dealerEmailAddress && !!touched.dealerEmailAddress ? errors.dealerEmailAddress : undefined
                  }
                />
                <Input
                  label={formatMessage(messages.dealerCellPhoneNumber)}
                  placeholder={formatMessage(messages.dealerCellPhoneNumber)}
                  name="dealerCellPhoneNumber"
                  type={'tel'}
                  onChange={handleChange}
                  value={values.dealerCellPhoneNumber}
                  error={
                    errors.dealerCellPhoneNumber && !!touched.dealerCellPhoneNumber
                      ? errors.dealerCellPhoneNumber
                      : undefined
                  }
                />
              </fieldset>
              <ButtonGroup align="right">
                <NavLink to={'/prequalification'}>
                  <Button>{formatMessage(messages.returnButton)}</Button>
                </NavLink>
                <Button type="submit" variant="secondary" onClick={handleSubmit} pending={pending} fullWidth={false}>
                  {formatMessage(messages.submit)}
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Body>
    </>
  );
};

export default ApplicationForm;
