import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'users.title',
    defaultMessage: 'My team',
  },
  invite: {
    id: 'users.invite',
    defaultMessage: 'Invite new user',
  },
  firstName: {
    id: 'users.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'users.lastName',
    defaultMessage: 'Last name',
  },
  email: {
    id: 'users.email',
    defaultMessage: 'Email',
  },
  roles: {
    id: 'users.roles',
    defaultMessage: 'Roles',
  },
  status: {
    id: 'users.status',
    defaultMessage: 'Status',
  },
  action: {
    id: 'users.action',
    defaultMessage: 'Action',
  },
  selectPlaceholder: {
    id: 'users.selectPlaceholder',
    defaultMessage: 'Select action',
  },
  submit: {
    id: 'users.submit',
    defaultMessage: 'Submit',
  },
  close: {
    id: 'users.close',
    defaultMessage: 'Close',
  },
  inviteTitle: {
    id: 'users.inviteTitle',
    defaultMessage: 'Invite new user',
  },
  suspended: {
    id: 'users.suspended',
    defaultMessage: 'User has been suspended successfully',
  },
});

export default messages;
