import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'dealerApplication.title',
    defaultMessage: 'Your details',
  },
  slogan: {
    id: 'approval.slogan',
    defaultMessage: 'Powered by',
  },
  dealershipInformation: {
    id: 'dealerApplication.dealershipInformation',
    defaultMessage: 'Dealership information',
  },
  dealerInformation: {
    id: 'dealerApplication.dealerInformation',
    defaultMessage: 'Dealer Information',
  },
  subtitle: {
    id: 'dealerApplication.subtitle',
    defaultMessage: 'Please provide the following details.',
  },
  notice: {
    id: 'dealerApplication.notice',
    defaultMessage: 'All fields are mandatory, unless otherwise noted.',
  },
  optional: {
    id: 'dealerApplication.optional',
    defaultMessage: 'optional',
  },
  textPlaceholder: {
    id: 'dealerApplication.textPlaceholder',
    defaultMessage: 'Please enter',
  },
  dealerShipName: {
    id: 'dealerApplication.dealerShipName',
    defaultMessage: 'Dealership Name',
  },
  dealerShipAddress: {
    id: 'dealerApplication.dealerShipAddress',
    defaultMessage: 'Dealership Address',
  },
  city: {
    id: 'dealerApplication.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'dealerApplication.state',
    defaultMessage: 'State',
  },
  zipCode: {
    id: 'dealerApplication.zipCode',
    defaultMessage: 'Zip code',
  },
  dealershipEmail: {
    id: 'dealerApplication.dealershipEmail',
    defaultMessage: 'Dealership Email',
  },
  dealershipPhoneNumber: {
    id: 'dealerApplication.dealershipPhoneNumber',
    defaultMessage: 'Dealership Phone Number',
  },
  dealerFirstName: {
    id: 'dealerApplication.dealerFirstName',
    defaultMessage: 'Dealer First Name',
  },
  dealerLastName: {
    id: 'dealerApplication.dealerLastName',
    defaultMessage: 'Dealer Last Name',
  },
  dealerAddress: {
    id: 'dealerApplication.dealerAddress',
    defaultMessage: 'Dealer Address',
  },
  dealerCity: {
    id: 'dealerApplication.dealerCity',
    defaultMessage: 'Dealer City',
  },
  dealerState: {
    id: 'dealerApplication.dealerState',
    defaultMessage: 'Dealer State',
  },
  dealerZipCode: {
    id: 'dealerApplication.dealerZipCode',
    defaultMessage: 'Dealer Zip code',
  },
  dealerEmailAddress: {
    id: 'dealerApplication.dealerEmailAddress',
    defaultMessage: 'Dealer Email Address',
  },
  dealerCellPhoneNumber: {
    id: 'dealerApplication.dealerCellPhoneNumber',
    defaultMessage: 'Dealer Cell Phone Number',
  },
  submit: {
    id: 'dealerApplication.submit',
    defaultMessage: 'Submit information ',
  },
  returnButton: {
    id: 'application.return',
    defaultMessage: 'Return to Homepage',
  },
});

export default messages;

export class electronicDisclosure {}
