import { format } from 'date-fns';

// This object formats a number as a US dollar amount.
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

// This object formats a date as a US date.
export const USDate = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
});

// This function converts a number to a percentage string.
// If the input is undefined or not a number, it returns '0.00%'.
export const NumberToPercentage = (num: number) => {
  if (num === undefined || isNaN(num)) return '0.00%';
  return num.toFixed(2) + '%';
};

// This function formats a phone number string.
// It removes non-digit characters, strips a leading 1 if present, and formats the result in the form '+1 (XXX) XXX-XXXX'.
// If the input doesn't match this format, it returns null.
// The regular expression used here is explained at https://stackoverflow.com/a/8358141
export function FormatPhoneNumber(phoneNumberString: string) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  // If the number starts with 1 (indicating a +1 prefix) and has an additional 10 digits, strip the 1.
  if (cleaned.length === 11 && cleaned[0] === '1') {
    cleaned = cleaned.substring(1);
  }
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+1 (' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

// This function converts a string to title case.
// It converts the string to lower case, splits it into words, capitalizes the first letter of each word, and joins the words back together with spaces.
export function FormatTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

// This function formats a date string in the form 'YYYY-MM-DD'.
export function FormatDate(date: string) {
  return format(date, 'YYYY-MM-DD');
}

// This function formats a date string in the form 'MM/dd/yyyy'.
export function formatDate(date: string) {
  return format(date, 'MM/dd/yyyy');
}

// This function formats a date string in the form 'hh:mm AM/PM'.
export function formatTime(date: string) {
  return format(date, 'hh:mm a');
}

export function capitaliseString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
