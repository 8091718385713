import { datadogRum } from '@datadog/browser-rum';
import getBaseApi from './getBaseApi.ts';

export const InitDatadog = (serviceName: string) => {
  datadogRum.init({
    ...getBaseConfig(),
    service: serviceName,
    env: import.meta.env.MODE,
    version: import.meta.env.VITE_DD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: import.meta.env.MODE === 'PRODUCTION' ? 'mask' : 'allow',
    allowedTracingUrls: [url => url.startsWith(getBaseApi() || 'http://localhost:3030')],
    beforeSend: event => {
      // If the event is an action, we need to ensure sensitive data is not shown, as by default,
      // DataDog will set the `.target.name` as the display value of the element.
      if (event.type == 'action' && event.action.target !== undefined) {
        // Redact emails
        event.action.target.name = event.action.target.name.replace(/\S+@\S+\.\S+/g, 'email_redacted');
        // Redact numbers
        event.action.target.name = event.action.target.name.replace(/\b\d+\b/g, 'X');
      }
      return true;
    },
  });
};
function getBaseConfig() {
  switch (import.meta.env.MODE) {
    case 'PRODUCTION':
      return {
        applicationId: 'b99b479d-abf1-4239-9e2b-29912dd061d5',
        clientToken: 'pub22d9945a6db0367d879ffc80fdb3e2ea',
        site: 'us3.datadoghq.com',
      };
    case 'STAGING':
      return {
        applicationId: 'b99b479d-abf1-4239-9e2b-29912dd061d5',
        clientToken: 'pub22d9945a6db0367d879ffc80fdb3e2ea',
        site: 'us3.datadoghq.com',
      };
    default:
      return {
        applicationId: '6e65b6a1-ad16-40d6-b98a-baa3cf308261',
        clientToken: 'pubdc0c4c7e7a2c38cb4f72af5b5d47c0aa',
        site: 'datadoghq.eu',
      };
  }
}
