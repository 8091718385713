import { useState, useEffect } from 'react';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import './EditableInput.scss';

type EditableInputProps = {
  title: string;
  index?: number;
  initialValue?: string | number;
  price?: number;
  editable?: boolean;
  onValueChange?: (title: string, initialValue?: string | number, index?: number) => void;
  selectOptions?: string[];
};

const EditableInput = ({
  title,
  index,
  initialValue,
  onValueChange,
  editable = true,
  selectOptions,
}: EditableInputProps) => {
  const [value, setValue] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  const isEmpty = value === '';
  const buttonText = editing ? 'save' : isEmpty ? 'add' : 'edit';

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="editableInput-outer">
      {!editing && !title.toLowerCase().includes('price') && !title.toLowerCase().includes('invoice') && <p>{value}</p>}
      {!editing && (title.toLowerCase().includes('price') || title.toLowerCase().includes('invoice')) && (
        <p>{USDollar.format(Number(value))}</p>
      )}
      {editing && selectOptions && (
        <select
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}>
          {selectOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
      {editing && !selectOptions && (
        <input
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
        />
      )}
      <button
        className={`editable-button ${!editable ? 'hidden' : ''}`}
        onClick={() => {
          if (editing) {
            onValueChange && onValueChange(title, value, index);
          }
          setEditing(!editing);
        }}>
        {buttonText}
      </button>
    </div>
  );
};

export default EditableInput;
