const DEALER_APPLICATION = 'dealer-application';
const SERIAL_NUMBER = 'serial-number';
const DEALER_PREQUALIFICATION = 'dealer-prequalification';
const RETRIEVE_SERIAL_NUMBERS = 'retrieve-serial-numbers';
const RETRIEVE_DEAL = 'retrieve-deal';
const RETRIEVE_DEAL_STATUS = 'retrieve-deal-status';
const SEND_CODE = 'send-code';
const USERS = 'users';
const PROGRAMS = 'programs';
const RETRIEVE_PREQUALIFICATION = 'retrieve-prequalification';
const PRICING_STATUS = 'pricing-status';
const START_PRICING = 'start-pricing';
const SEND_CONSENT = 'send-consent';
const VERIFY_CONSENT = 'verify-consent';
const RATE_SHEET = 'rate-sheet';
const MAKE_MODELS = 'make-models';
const PRODUCT_TYPES = 'product-types';
const DEALER_INFO = 'dealer-info';
const PROGRAM_TERMS = 'program-terms';
const RESEND_IDENTITY_VERIFICATION = 'reset-identity-verification';
const APPLICATION_SUMMARY = 'application-summary';

export {
  DEALER_APPLICATION,
  DEALER_PREQUALIFICATION,
  SERIAL_NUMBER,
  RETRIEVE_SERIAL_NUMBERS,
  RETRIEVE_DEAL,
  RETRIEVE_DEAL_STATUS,
  USERS,
  PROGRAMS,
  RETRIEVE_PREQUALIFICATION,
  SEND_CODE,
  PRICING_STATUS,
  START_PRICING,
  RATE_SHEET,
  MAKE_MODELS,
  SEND_CONSENT,
  VERIFY_CONSENT,
  PRODUCT_TYPES,
  DEALER_INFO,
  PROGRAM_TERMS,
  RESEND_IDENTITY_VERIFICATION,
  APPLICATION_SUMMARY,
};
