import axios from 'axios';

import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { SEND_CODE } from './constants';
import { SendCodeEmailRequest } from '@luna-protocol/core/src/types';

const useSendCode = () => {
  const mutation = useMutation({
    mutationKey: [SEND_CODE],
    mutationFn: (data: SendCodeEmailRequest) => {
      return axios.post(`${getBaseApi()}/auth/otp/dealer/send`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' },
      });
    },
  });

  return {
    sendCode: mutation.mutate,
    ...mutation,
  };
};

export { useSendCode };
