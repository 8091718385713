import axios from 'axios';
import { useQuery } from 'react-query';
import { DEALER_PREQUALIFICATION } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

type Customer = {
  borrowing_amount: number;
  created_at: string;
  first_name: string;
  last_name: string;
  loan_application_id: string;
  make: string;
  model: string;
  phone_number: string;
  state: string;
  zip_code: string;
  dealer_name: string;
  customer_status: string;
  idv_status: boolean;
  customer_accepted_prequal: boolean;
};

type CustomerRequest = {
  searchKey: string;
  page: number;
  page_size: number;
  triggerSearch?: boolean;
  orderColumn?: string;
  orderDirection?: string;
};

export type CustomersList = Customer[];

const getCustomers = async ({ searchKey, page, page_size, orderDirection, orderColumn }: CustomerRequest) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('user_token')}`,
  };
  let url = `${getBaseApi()}/dealer/customers?page_size=${page_size}&page=${page}`;

  if (searchKey !== '') {
    url += `&search=${encodeURIComponent(searchKey)}`;
  }

  if (orderColumn !== '') {
    url += `&order_by=${orderColumn}`;
  }
  if (orderDirection !== '') {
    url += `&order_direction=${orderDirection}`;
  }

  const resp = await axios.get(url, { headers });

  const totalCount = resp.headers['x-total-count'] || 0;

  return { data: resp.data, totalCount };
};

const useGetCustomers = ({
  searchKey,
  triggerSearch,
  page,
  page_size,
  orderDirection,
  orderColumn,
}: CustomerRequest) => {
  return useQuery(
    [DEALER_PREQUALIFICATION, triggerSearch, page, page_size, orderDirection, orderColumn],
    async () => {
      return await getCustomers({ searchKey, page, page_size, orderDirection, orderColumn });
    },
    { enabled: true, retryDelay: 5000 },
  );
};

export { useGetCustomers };
