import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

import { RESEND_IDENTITY_VERIFICATION } from './constants';

export const postResendIdv = (loan_application_id: string) =>
  axios.post(`${getBaseApi()}/dealer/application/${loan_application_id}/resend-idv`, null, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  });

const usePostResendIdv = (loanApplicationID?: string) => {
  return useMutation(
    [RESEND_IDENTITY_VERIFICATION, loanApplicationID],
    () => {
      if (loanApplicationID === undefined) {
        throw new Error('Loan application ID is undefined');
      }
      return postResendIdv(loanApplicationID);
    },
    {},
  );
};
export { usePostResendIdv };
