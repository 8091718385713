import { OEMType as OEMTypeEnum } from '@luna-protocol/core/src/types.ts';
import config from '@luna-protocol/core/src/utils/feature-flags.json';
import { getSerialNumberItemTitle } from '../components/AmendDetails/getProducts.tsx';
import { OrderSummary, SerialNumber } from '../types.ts';

export const buildProductList = (dataObj?: OrderSummary, OEMType?: string, base_type?: string) => {
  if (dataObj) {
    const serialNumberItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const productTitle = getSerialNumberItemTitle(product.serial_number_type, 'Number');
      return {
        title: productTitle,
        value: product.serial_number,
        type: product.serial_number_type + '_' + i,
      };
    });

    const collateralIdItems = dataObj?.serial_numbers.map((product: SerialNumber, i) => {
      return {
        title: 'Collateral ID',
        value: product.collateral_id,
        type: product.serial_number_type + '_' + i,
      };
    });

    const priceItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const PriceTitle = getSerialNumberItemTitle(product.serial_number_type, 'Price');
      return {
        title: PriceTitle,
        value: product.product_price?.toFixed(2),
        type: product.serial_number_type + '_' + i,
      };
    });

    const yearItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const yearTitle = getSerialNumberItemTitle(product.serial_number_type, 'Year');
      return {
        title: yearTitle,
        value: product.year,
        type: product.serial_number_type + '_' + i,
      };
    });

    const conditionItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const ConditionTitle = getSerialNumberItemTitle(product.serial_number_type, 'Condition');
      return {
        title: ConditionTitle,
        value: product.condition.charAt(0).toUpperCase() + product.condition.slice(1),
        type: product.serial_number_type + '_' + i,
      };
    });

    // const sellerPurchasePriceItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
    //   const isKTM = OEMType === 'ktm';
    //   const PurchasePriceTitle = getSerialNumberItemTitle(
    //     product.serial_number_type,
    //     product.condition === 'new' && isKTM ? 'MSRP Amount' : product.condition === 'new' ? 'Invoice' : 'NADA',
    //   );
    //   return {
    //     title: PurchasePriceTitle,
    //     value: product.seller_purchase_price?.toFixed(2),
    //     type: product.serial_number_type + '_' + i,
    //   };
    // });

    const makeItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const makeTitle = getSerialNumberItemTitle(product.serial_number_type, 'Make');
      return {
        title: makeTitle,
        value: product.make,
        type: product.serial_number_type + '_' + i,
      };
    });

    const modelItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const modelTitle = getSerialNumberItemTitle(product.serial_number_type, 'Model');
      return {
        title: modelTitle,
        value: product.model,
        type: product.serial_number_type + '_' + i,
      };
    });

    const colorItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const colorTitle = getSerialNumberItemTitle(product.serial_number_type, 'Color');
      return {
        title: colorTitle,
        value: product.color,
        type: product.serial_number_type + '_' + i,
      };
    });

    const nadaItems = dataObj.serial_numbers.map((product: SerialNumber, i) => {
      const title = getSerialNumberItemTitle('', 'NADA/MSRP Invoice');

      let label = 'NADA';
      if (OEMType === OEMTypeEnum.Brunswick || (config.FF_OEMTemplatesEnabled && base_type === 'marine')) {
        if (product.condition?.toLocaleLowerCase() === 'new') {
          label = 'Invoice';
        } else {
          label = 'NADA';
        }
      }
      if (
        OEMType === OEMTypeEnum.KTM ||
        OEMType === OEMTypeEnum.EZGO ||
        (config.FF_OEMTemplatesEnabled && base_type === 'powersports')
      ) {
        if (product.condition?.toLocaleLowerCase() === 'new') {
          label = 'MSRP';
        } else {
          label = 'NADA';
        }
      }
      return {
        label,
        title,
        value: product.seller_purchase_price?.toFixed(2),
        type: product.serial_number_type + '_' + i,
      };
    });

    const x = [
      {
        title: 'Item',
        value: dataObj?.item_name,
      },
      ...makeItems,
      ...modelItems,
      ...colorItems,
      ...yearItems,
      ...serialNumberItems,
      ...priceItems,
      ...conditionItems,
      ...nadaItems,
      ...collateralIdItems,
      // ...sellerPurchasePriceItems,
    ];
    return x;
  }
  return [];
};
