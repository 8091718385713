import { Field } from 'formik';
import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import messages from './Checkbox.messages.ts';

import './Checkbox.scss';

interface CheckboxProps {
  label: string;
  value?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
  error?: string;
  disclaimer?: string;
  required?: boolean;
  showOptionalLabel?: boolean;
  className?: string;
}

const Checkbox = ({
  label,
  onChange,
  name,
  disabled,
  error,
  disclaimer,
  required = true,
  value,
  showOptionalLabel = true,
  className,
}: CheckboxProps) => {
  const { formatMessage } = useIntl();
  return (
    <div className={`checkbox-parent${className ? ` ${className}` : ''}`}>
      <div className="checkbox-container">
        <Field
          className="checkbox-input"
          type="checkbox"
          name={name}
          id={name}
          title={name}
          disabled={disabled}
          {...(value !== undefined ? { checked: value } : {})}
          onChange={onChange}
          aria-label={`${name}--input`}
          data-testid={`${name}--input`}
        />
        <label htmlFor={name} className="checkbox-label" aria-label={`${name} label`} title={`${name} label`}>
          {label}
          {!required && showOptionalLabel && <span className="optional">({formatMessage(messages.optional)})</span>}
        </label>
      </div>
      <div className="checkbox-invalid-feedback">
        <p className="error">{error}</p>
      </div>
      {disclaimer && <p className="checkbox-disclaimer disclaimer">{disclaimer}</p>}
    </div>
  );
};

export default Checkbox;
