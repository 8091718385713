import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { AppContext } from '../AppContext.js';
import { DealInformation, LienInformationRequest } from '@luna-protocol/core/src/types';

const usePostBuildDeal = () => {
  const { loanApplicationID } = useContext(AppContext);

  const mutation = useMutation((updateBuildDealDetails?: Partial<DealInformation>) => {
    const { ...updates } = updateBuildDealDetails;
    return axios.post(`${getBaseApi()}/dealer/application/${loanApplicationID}/deal`, JSON.stringify(updates), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    postBuildDealDetails: mutation.mutate,
    ...mutation,
  };
};

const usePostLienDeal = () => {
  const { loanApplicationID } = useContext(AppContext);

  const mutation = useMutation((updateBuildDealLien?: LienInformationRequest) => {
    const { ...lienInfo } = updateBuildDealLien;
    const details = lienInfo.deduction[0];

    const requestBody = {
      lien_holder: details.name,
      loan_value: details.value,
      market_value: details.value,
      make: '',
      model: '',
      year: '0',
    };

    return axios.post(`${getBaseApi()}/dealer/application/${loanApplicationID}/trade-in`, JSON.stringify(requestBody), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    postLienProvider: mutation.mutate,
    ...mutation,
  };
};

export { usePostBuildDeal, usePostLienDeal };
