import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import axios from 'axios';
import { useQuery } from 'react-query';
import { OEMType as EOEMType } from '../types';
import { DEALER_BRANDING } from './constants';

interface DealerBranding {
  display_name: 'Aqua Dealer';
  dealer_logo_base64?: string;
  dealer_logo_content_type?: string;
  oem_type?: EOEMType;
  flow_template_id?: string;
}

const getDealershipByDealerId = async (dealerId: string) => {
  // for some reason, the dealerId is wrapped in quotes
  const sanitizedDealerId = dealerId.replace(/"/g, '');

  if (sanitizedDealerId === '' || sanitizedDealerId == 'undefined') {
    sessionStorage.setItem('OEMType', JSON.stringify(EOEMType.Brunswick));
    return;
  }

  const resp = await axios.get(`${getBaseApi()}/dealer/${sanitizedDealerId}/branding`);

  if (resp.status !== 200) {
    sessionStorage.setItem('OEMType', JSON.stringify(EOEMType.Brunswick));
    throw new Error('Failed to get dealer branding');
  }

  if (resp.data.oem_type) {
    sessionStorage.setItem('OEMType', JSON.stringify(resp.data.oem_type));
  } else {
    sessionStorage.setItem('OEMType', JSON.stringify(EOEMType.Brunswick));
  }

  return resp.data as DealerBranding;
};

const useGetDealerBranding = (dealerId: string) => {
  return useQuery(
    [DEALER_BRANDING],
    () => {
      return getDealershipByDealerId(dealerId);
    },
    {
      enabled: !!dealerId,
      retry: false,
    },
  );
};

export { useGetDealerBranding };
