import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'amendDetails.title',
    defaultMessage: 'Confirm Order Details',
  },
});

export default messages;
