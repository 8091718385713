import axios from 'axios';
import { useQuery } from 'react-query';
import { APPLICATION_SUMMARY } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface Applicant {
  customer_id: string;
  cell_phone_number: string;
  first_name: string;
  idv_complete: boolean;
  is_primary: boolean;
  last_name: string;
  poi_complete: boolean;
  address_lines: [string];
  city: string;
  county: string;
  state: string;
  zip_code: string;
}

export interface ApplicationSummary {
  applicants: [Applicant];
  dealership_name: string;
  delivery_date: string;
  make: string;
  model: string;
  prequal_amount: number;
  prequal_date: string;
  status: string;
}

const getApplicationSummary = async (loanApplicationID: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/application/${loanApplicationID}/summary`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get application summary');
  }
  return resp.data as ApplicationSummary;
};

const useGetApplicationSummary = (loanApplicationID: string) => {
  return useQuery(
    [APPLICATION_SUMMARY, loanApplicationID],
    async () => {
      if (loanApplicationID === undefined) {
        throw new Error('Loan application ID is undefined');
      }
      return await getApplicationSummary(loanApplicationID);
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};
export { useGetApplicationSummary };
