import axios from 'axios';
import { useQuery } from 'react-query';
import { USERS } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export type User = {
  dealer_user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  status: string;
  roles: string[];
};

export interface UsersData {
  dealer_users: User[];
}

const getCustomers = async () => {
  const resp = await axios.get(`${getBaseApi()}/dealer/users`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get users');
  }
  return resp.data.dealer_users as UsersData;
};

const useGetUsers = () => {
  return useQuery(
    [USERS],
    () => {
      return getCustomers();
    },
    { enabled: true },
  );
};
export { useGetUsers };
