import axios from 'axios';
import { useMutation } from 'react-query';
import { PasswordResetRequest } from '@luna-protocol/core/src/types';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

const usePatchPassword = () => {
  const mutation = useMutation(async (data: PasswordResetRequest) => {
    const resp = await axios.post(`${getBaseApi()}/dealer/reset-password`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
    if (resp.status !== 200 || resp.data.error) {
      throw new Error('Invalid verification code');
    }
    return resp.data;
  });

  return {
    patchPassword: mutation.mutate,
    ...mutation,
  };
};

export { usePatchPassword };
