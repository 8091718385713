import { Form, Formik, FormikProps } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup.tsx';
import Input from '@luna-protocol/core/src/components/Input/Input';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import { AppContext } from '../../AppContext.tsx';
import { useGetPrequalificationUsingRetrival } from '../../queries/useGetPrequalificationReference.ts';
import useClearSessionData from '../../utils/hooks/useClearSessionData.ts';
import messages from './RetrievePrequalification.messages';
import { retrievePrequalificationSchema } from './retrievePrequalificationSchema.ts';

interface RetrieveFormType {
  referenceNumber: string;
}

const RetrievePrequalification = () => {
  useAuth();
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<RetrieveFormType>>(null);
  const { formatMessage } = useIntl();
  const [refNumber, setRefNumber] = useState<string | undefined>();
  const { data } = useGetPrequalificationUsingRetrival(refNumber);
  const { setLoanApplicationID } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const clearSessionData = useClearSessionData();

  useEffect(() => {
    if (code) {
      setRefNumber(code);
    }
  }, [code]);

  const retrieveInitialValues = {
    referenceNumber: '',
  };

  const onRetrieveSubmit = ({ referenceNumber }: RetrieveFormType) => {
    setRefNumber(referenceNumber);
  };

  useEffect(() => {
    if (data?.loan_application_id !== undefined) {
      clearSessionData();
      setLoanApplicationID(data?.loan_application_id);
      navigate('/amend_details');
    } else if (data?.error !== undefined && formikRef.current !== null) {
      formikRef.current.setErrors({ referenceNumber: formatMessage(messages.invalidReferenceNumber) });
    }
  }, [data, formikRef, formatMessage, navigate, setLoanApplicationID]);

  return (
    <>
      <BackButton path="/prequalification" inverted />
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        <p>{formatMessage(messages.instruction)}</p>
        <Formik
          innerRef={formikRef}
          initialValues={retrieveInitialValues}
          onSubmit={onRetrieveSubmit}
          validationSchema={retrievePrequalificationSchema}>
          {({ values, errors, handleBlur, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                label={formatMessage(messages.inputLabel)}
                placeholder={formatMessage(messages.inputPlaceholder)}
                name="referenceNumber"
                onChange={handleChange}
                value={values.referenceNumber}
                error={errors.referenceNumber}
                onBlur={handleBlur}
              />
              <ButtonGroup align="left">
                <Button fullWidth={false} type="submit" variant="secondary">
                  {formatMessage(messages.submit)}
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Body>
    </>
  );
};

export default RetrievePrequalification;
