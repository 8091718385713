import { useIntl } from 'react-intl';
import messages from './Footer.messages';
import UserFeedback from '../UserFeedback/UserFeedback.tsx';
import './Footer.scss';

const Footer = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <div className="Footer">
        <span>{formatMessage(messages.body)}</span>
      </div>
      {!(import.meta.env.MODE === 'PRODUCTION' || import.meta.env.MODE === 'STAGING') && (
        <div className="Feedback">
          <UserFeedback />
        </div>
      )}
    </>
  );
};

export default Footer;
