import { Fragment } from 'react/jsx-runtime';
import caret from '../../assets/pagination-caret.png';
import CustomButton from '../CustomButton/CustomButton';
import './Pagination.scss';
import { useMemo } from 'react';

type PaginationProps = {
  total: number | undefined;
  limit: number | undefined;
  page: number | undefined;
  changePage: (page: number) => void | undefined;
};

const Pagination = ({ total = 1, page = 1, changePage, limit = 10 }: PaginationProps) => {
  const totalPages = useMemo(() => Math.ceil(total / limit), [total, limit]);
  const currentPage = page;

  return (
    <div className="pagination-component">
      <CustomButton
        name="pagination-previous-button"
        testId="pagination-previous-button"
        onClick={() => changePage(page - 1)}
        disabled={page === 1}>
        <img className="previous--button" src={caret} alt="previous" />
      </CustomButton>
      <div className="pagination-page-numbers">
        {Array.from({ length: totalPages }, (_, index) => {
          const pageNumber = index + 1;
          const activePage = currentPage === pageNumber;

          // Add condition to limit the number of items
          if (
            totalPages > 9 &&
            pageNumber !== 1 &&
            pageNumber !== totalPages &&
            (pageNumber < page - 4 || pageNumber > page + 4)
          ) {
            // Add ellipsis
            if (pageNumber === page - 5 || pageNumber === page + 5) {
              return (
                <Fragment key={index}>
                  <span className="pagination-ellipsis">&#8230;</span>
                </Fragment>
              );
            }
            return null;
          }

          return (
            <>
              <CustomButton
                onClick={() => changePage(pageNumber)}
                customClass={`pagination-page-number ${activePage ? 'active' : ''}`}>
                {pageNumber}
              </CustomButton>
            </>
          );
        })}
      </div>
      <CustomButton
        name="pagination-next-button"
        testId="pagination-next-button"
        onClick={() => changePage(page + 1)}
        disabled={page === totalPages - 1}>
        <img src={caret} alt="next" />
      </CustomButton>
    </div>
  );
};

export default Pagination;
