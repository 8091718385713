import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'totals.title',
    defaultMessage: 'Pre-qualified financing amount',
  },
  basePrice: {
    id: 'totals.basePrice',
    defaultMessage: 'Base product price \n and accessories',
  },
  feesTitle: {
    id: 'totals.feesTitle',
    defaultMessage: 'Fees and taxes',
  },
  productsTitle: {
    id: 'totals.productsTitle',
    defaultMessage: 'Back end products',
  },
  deductionsTitle: {
    id: 'totals.deductionsTitle',
    defaultMessage: 'Deductions',
  },
  totalRequested: {
    id: 'totals.totalRequested',
    defaultMessage: 'Total financing requested',
  },
  frontendLTV: {
    id: 'totals.frontendLTV',
    defaultMessage: 'Frontend LTV',
  },
  backendProducts: {
    id: 'totals.backendProducts',
    defaultMessage: 'Backend products',
  },
  backendLTV: {
    id: 'totals.backendLTV',
    defaultMessage: 'Backend LTV',
  },
});

export default messages;
