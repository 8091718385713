import axios from 'axios';

import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { SEND_CONSENT } from './constants';
import { SendCodeEmailRequest } from '@luna-protocol/core/src/types';

const useSendConsent = () => {
  const mutation = useMutation({
    mutationKey: [SEND_CONSENT, false],
    mutationFn: (data: SendCodeEmailRequest) => {
      return axios.post(
        `${getBaseApi()}/auth/otp/send_consent`,
        JSON.stringify({
          ...data,
          send_disclaimer: false,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        },
      );
    },
  });

  return {
    sendCode: mutation.mutate,
    ...mutation,
  };
};

export { useSendConsent };
