import Complete from '@luna-protocol/core/src/components/Complete/Complete';
import { useIntl } from 'react-intl';
import messages from './Expired.messages.ts';

const Expired = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Complete
        title={formatMessage(messages.title)}
        body={[
          formatMessage(messages.body, {
            link: <a href="mailto:recsupport@aquafinance.com">recsupport@aquafinance.com</a>,
          }),
        ]}
      />
    </>
  );
};

export default Expired;
