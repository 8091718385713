import { defineMessages } from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'dataTable.loading',
    defaultMessage: 'Loading...',
  },
  noData: {
    id: 'dataTable.noData',
    defaultMessage: 'No data available',
  },
  updated: {
    id: 'dataTable.updated',
    defaultMessage: 'Dealer list, updated {date}',
  },
});

export default messages;
