import React from 'react';
import './CustomButton.scss';

type CustomButtonProps = {
  children?: React.ReactNode;
  onClick: () => void;
  link?: boolean;
  customClass?: string;
  testId?: string;
  name?: string;
  disabled?: boolean;
};

const CustomButton = ({
  children,
  onClick,
  link = false,
  customClass,
  testId,
  name,
  disabled = false,
}: CustomButtonProps) => {
  return (
    <button
      className={`custom-button ${link ? 'link' : ''} ${customClass}`}
      onClick={onClick}
      name={name}
      data-testid={testId === undefined ? `button-${name}` : testId}
      aria-label={name}
      title={name}
      disabled={disabled}>
      {children}
    </button>
  );
};

export default CustomButton;
