import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export type ProgramRequest = {
  loan_application_id: string;
  loan_program_id: string;
};

const usePostSelectProgram = () => {
  const mutation = useMutation(({ loan_application_id, loan_program_id }: ProgramRequest) => {
    return axios.post(
      `${getBaseApi()}/dealer/application/${loan_application_id}/programs/${loan_program_id}/select`,
      null,
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
      },
    );
  });

  return {
    selectProgram: mutation.mutate,
    ...mutation,
  };
};

export { usePostSelectProgram };
