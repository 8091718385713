import axios from 'axios';
import { useQuery } from 'react-query';
import { OrderSummary } from '../types';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { RETRIEVE_SERIAL_NUMBERS } from './constants.ts';

export const getSerialNumbers = async (loanApplicationID: string) => {
  const resp = await axios.get(`${getBaseApi()}/dealer/application/${loanApplicationID}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get serial numbers');
  }
  return resp.data as OrderSummary;
};

const useGetSerialNumbers = (loanApplicationID?: string, onSuccess?: (serialNumbersData: OrderSummary) => void) => {
  return useQuery(
    [RETRIEVE_SERIAL_NUMBERS, loanApplicationID],
    async () => {
      if (loanApplicationID === undefined) {
        throw new Error('Loan application ID is undefined');
      }
      return await getSerialNumbers(loanApplicationID);
    },
    {
      enabled: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...(onSuccess && { onSuccess }),
    },
  );
};
export { useGetSerialNumbers };
