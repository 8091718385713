import axios from 'axios';

import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { PostVerificationRequest } from '@luna-protocol/core/src/types';

const useVerifyConsent = () => {
  const mutation = useMutation(async (data: PostVerificationRequest) => {
    const resp = await axios.post(
      `${getBaseApi()}/auth/otp/verify_consent`,
      JSON.stringify({
        ...data,
      }),
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
      },
    );
    if (resp.status !== 200) {
      throw new Error('Invalid verification code');
    }
    return resp.data;
  });

  return {
    postAuth: mutation.mutate,
    ...mutation,
  };
};

export { useVerifyConsent };
