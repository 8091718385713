import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, useActionData, useLocation, useNavigate } from 'react-router-dom';
import { useInterval, useSessionStorage } from 'usehooks-ts';

import isLoggedIn from '@luna-protocol/core/src/utils/isLoggedIn.ts';
import { UseMutateFunction } from 'react-query';
import { OEMType as EOEMType, LoginCredentials, LoginResponse } from '../../types.ts';
import Banner from '../Banner/Banner.tsx';
import Body from '../Body/Body.tsx';
import Button from '../Button/Button.tsx';
import ButtonGroup from '../ButtonGroup/ButtonGroup.tsx';
import Input from '../Input/Input.tsx';
import messages from './Login.messages';
import './Login.scss';
import { loginSchema } from './LoginSchema.ts';

export type LoginProps = {
  login: () => { login: UseMutateFunction<LoginResponse, unknown, LoginCredentials, unknown> };
  postLoginRoute: string;
  oem_type?: EOEMType;
};

const Login = ({ login: loginHook, postLoginRoute, oem_type = EOEMType.Brunswick }: LoginProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [missingEmail, setMissingEmail] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(false);
  const { login } = loginHook();
  const { state } = useLocation();
  const [OEMType, setOEMType] = useSessionStorage<EOEMType>('OEMType', oem_type);
  const [pending, setPending] = useState(false);

  if (state) {
    const { to } = state as {
      to?: string;
    };
    if (to) {
      postLoginRoute = to;
    }
  }

  useEffect(() => {
    // left in intentionally for use without erroring
    console.info('Current oem_type', OEMType);
    setOEMType(oem_type);
  }, [oem_type]);

  useEffect(() => {
    if (isLoggedIn()) {
      // if already signed in, redirect to post login route page
      navigate(postLoginRoute);
    } else {
      // clear local storage
      localStorage.removeItem('user_token');
      localStorage.removeItem('dealer_id');
    }
  }, [navigate]);

  // remove this result logic
  //@ts-expect-error Type 'unknown' is not assignable to type
  const result: {
    error_message: string;
  } = useActionData();
  const [unlockMinutesLeft, setUnlockMinutesLeft] = useState(5);

  useInterval(
    () => {
      setUnlockMinutesLeft(unlockMinutesLeft - 1);
    },
    result?.error_message === 'locked' && unlockMinutesLeft > 0 ? 60000 : null,
  );

  const initialValues: LoginCredentials = {
    email_address: '',
    password: '',
  };

  const onSubmit = (values: LoginCredentials) => {
    setPending(true);
    login(values, {
      onSuccess: () => {
        navigate(postLoginRoute);
      },
      onError: context => {
        setPending(false);
        console.error('error', context);
        setErrorSubmitting(true);
      },
    });
  };

  const handlePasswordReset = (values: LoginCredentials) => {
    setMissingEmail(false);
    if (!values.email_address) {
      setMissingEmail(true);
      return;
    }
    navigate('/email_verification', {
      state: { emailAddress: values.email_address, to: '/password_reset', autoSend: true },
    });
  };

  return (
    <>
      <Banner OEMType={oem_type}>{formatMessage(messages.title)}</Banner>
      <Body>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginSchema}>
          {({ values, errors, handleBlur, touched, handleSubmit, handleChange }) => (
            <Form>
              <Input
                label={formatMessage(messages.emailAddressLabel)}
                placeholder={formatMessage(messages.emailAddressLabel)}
                name="email_address"
                onChange={handleChange}
                value={values.email_address}
                error={!!touched.email_address && errors.email_address ? errors.email_address : undefined}
                onBlur={handleBlur}
              />
              <Input
                label={formatMessage(messages.passwordLabel)}
                placeholder={formatMessage(messages.passwordLabel)}
                name="password"
                onChange={handleChange}
                value={values.password}
                error={!!touched.password && errors.password ? errors.password : undefined}
                onBlur={handleBlur}
                type="password"
              />
              {(errorSubmitting || missingEmail) && (
                <p className="error">{formatMessage(messages.invalidEmailOrPassword)}</p>
              )}
              {result?.error_message === 'locked' && unlockMinutesLeft > 0 && (
                <p className="error">
                  {formatMessage(messages.invalidEmailOrPasswordLocked).replace('5', `${unlockMinutesLeft}`)}
                </p>
              )}
              <ButtonGroup>
                <Button
                  type="button"
                  onClick={() => handlePasswordReset(values)}
                  testId="login-reset"
                  disabled={values.email_address === '' || errors.email_address !== undefined}>
                  {formatMessage(messages.resetPasswordButton)}
                </Button>
                <Button
                  type="submit"
                  variant="secondary"
                  onClick={handleSubmit}
                  pending={pending}
                  testId="login-submit">
                  {formatMessage(messages.signInButton)}
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Body>
    </>
  );
};

export default Login;
