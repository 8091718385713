import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import isLoggedIn from './isLoggedIn.ts';
import { getPayloadFromToken } from './getCustomerIdFromToken.ts';

export type AuthProps = {
  to?: string;
};

export const useAuth = (props?: AuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const unsetAuthInfo = () => {
    localStorage.removeItem('user_token');
    localStorage.removeItem('dealer_id');
  };

  const isDealerAndHasNoID = () => {
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const jwt = queryParams.get('token') || localStorage.getItem('user_token');

    if (jwt === null) {
      unsetAuthInfo();
      return true;
    }

    const payload = getPayloadFromToken(jwt);

    if (queryParams.get('token') && payload.user_type === 'dealer') {
      // special case where the BE is dropping us into a flow as a specific dealer (usually for contract signing). In this case we should update the dealer_id in local storage to match
      localStorage.setItem('dealer_id', payload.dealer_id);
      sessionStorage.setItem('is_standalone_dealer', payload.is_standalone_dealer);
    }

    // Check if the token is expired
    const currentTime = Math.floor(Date.now() / 1000); // get current time in seconds
    if (payload.exp <= currentTime) {
      unsetAuthInfo();
      return true; // token is expired
    }

    // sense check the dealer_id matches the token
    if (payload.user_type === 'dealer') {
      const dealerID = localStorage.getItem('dealer_id');
      if (dealerID != payload.dealer_id) {
        unsetAuthInfo();
        return true;
      }
    }

    localStorage.setItem('user_token', jwt);
    return false;
  };

  useEffect(() => {
    if (isDealerAndHasNoID() && !isLoggedIn() && import.meta.env.MODE !== 'test') {
      navigate('/login', {
        state: {
          to: props?.to,
        },
      });
    }
  }, []);
};
