import { ReactNode } from 'react';
import './Hero.scss';

interface HeroProps {
  children?: ReactNode;
}

export const Hero = ({ children }: HeroProps) => <div className="hero">{children}</div>;

export default Hero;
