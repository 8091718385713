/**
 * This component renders a calendar using the react-datepicker library.
 *
 * @component
 * @param {Date} selectedDate - The currently selected date in the calendar.
 * @param {Function} setDate - A function to set the selected date when it changes.
 * @param {number} [maxDate=60] - The maximum number of days from the current date that can be selected.
 * @returns {JSX.Element} A calendar component with functionality to select dates.
 */

import { addDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';

type CalendarProps = {
  selectedDate: Date;
  setDate: (date: Date) => void;
  maxDate?: number;
};

const Calendar = ({ selectedDate, setDate, maxDate = 60 }: CalendarProps) => {
  const startDate = new Date();

  return (
    <>
      <div className="calendar-content" data-testid="calendar">
        <DatePicker
          selected={selectedDate}
          minDate={startDate}
          onChange={date => setDate(date ?? new Date())}
          maxDate={addDays(new Date(), maxDate)}
          inline
        />
      </div>
    </>
  );
};

export default Calendar;
