import './CustomerName.scss';

type CustomerNameProps = {
  applicantName: string;
};

const CustomerName = ({ applicantName }: CustomerNameProps) => (
  <div className="customer-name-container">
    <h3>{applicantName}</h3>
  </div>
);

export default CustomerName;
