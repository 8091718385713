import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'reviewDeal.title',
    defaultMessage: 'View your total deal summary',
  },
  subtitle: {
    id: 'reviewDeal.subtitle',
    defaultMessage: 'Summary',
  },
  itemTitle: {
    id: 'reviewDeal.itemTitle',
    defaultMessage: 'Item details',
  },
  financeTitle: {
    id: 'reviewDeal.financeTitle',
    defaultMessage: 'Financing details',
  },
  deliveryTitle: {
    id: 'reviewDeal.deliveryTitle',
    defaultMessage: 'Delivery date',
  },
  emailOverride: {
    id: 'reviewDeal.emailOverride',
    defaultMessage: 'Dealer signature email override',
  },
  emailLabel: {
    id: 'reviewDeal.emailLabel',
    defaultMessage: 'Dealer email',
  },
  invalidEmail: {
    id: 'reviewDeal.invalidEmail',
    defaultMessage: 'Please enter a valid email address',
  },
  sidebarTitle: {
    id: 'reviewDeal.sidebarTitle',
    defaultMessage: 'Net cash summary',
  },
  attestationCheckbox: {
    id: 'reviewDeal.attestationCheckbox',
    defaultMessage: 'By ticking this box you agree that all information above is correct.',
  },
  documentSendMessage: {
    id: 'reviewDeal.documentSendMessage',
    defaultMessage: 'Do not send the documents for signature(s) until the day of delivery',
  },
  saveButton: {
    id: 'reviewDeal.saveButton',
    defaultMessage: 'Close',
  },
  completeButton: {
    id: 'reviewDeal.completeButton',
    defaultMessage: 'Complete purchase and financing now',
  },
  itemLabel: {
    id: 'reviewDeal.itemLabel',
    defaultMessage: 'Item',
  },
  modelLabel: {
    id: 'reviewDeal.modelLabel',
    defaultMessage: 'Model',
  },
  nada_msrp_invoice: {
    id: 'reviewDeal.nada_msrp_invoice',
    defaultMessage: 'MSRP/Invoice NADA',
  },
  totalLabel: {
    id: 'reviewDeal.totalLabel',
    defaultMessage: 'Total',
  },
  customerNameLabel: {
    id: 'reviewDeal.customerNameLabel',
    defaultMessage: 'Customer name',
  },
  totalAmountFinancedLabel: {
    id: 'reviewDeal.totalAmountFinancedLabel',
    defaultMessage: 'Total amount financed',
  },
  monthlyRepaymentLabel: {
    id: 'reviewDeal.monthlyRepaymentLabel',
    defaultMessage: 'Monthly Payment',
  },
  repaymentPeriodLabel: {
    id: 'reviewDeal.repaymentPeriodLabel',
    defaultMessage: 'Terms',
  },
  aprLabel: {
    id: 'reviewDeal.aprLabel',
    defaultMessage: 'Interest Rate',
  },
  confirmedHINLabel: {
    id: 'reviewDeal.confirmedHINLabel',
    defaultMessage: 'Confirmed HIN',
  },
  confirmedVINLabel: {
    id: 'reviewDeal.confirmedVINLabel',
    defaultMessage: 'Confirmed VIN',
  },
  confirmedSerialNumberLabel: {
    id: 'reviewDeal.confirmedSerialNumberLabel',
    defaultMessage: 'Confirmed serial number',
  },
  confirmedTrailerLabel: {
    id: 'reviewDeal.confirmedTrailerLabel',
    defaultMessage: 'Trailer serial number',
  },
  confirmedSerialNumber: {
    id: 'reviewDeal.confirmedSerialNumber',
    defaultMessage: 'Confirmed Serial Number',
  },
  confirmedEngineLabel: {
    id: 'reviewDeal.confirmedEngineLabel',
    defaultMessage: 'Engine serial number',
  },
  netFloorplanBalanceLabel: {
    id: 'reviewDeal.netFloorplanBalanceLabel',
    defaultMessage: 'Net floorplan balance',
  },
  subsidisedFinancingEndDateLabel: {
    id: 'reviewDeal.subsidisedFinancingEndDateLabel',
    defaultMessage: 'Subsidised financing end date',
  },
  conditionLabel: {
    id: 'reviewDeal.conditionLabel',
    defaultMessage: 'New or used',
  },
  nadaValueLabel: {
    id: 'reviewDeal.nadaValueLabel',
    defaultMessage: 'NADA amount',
  },
  invoiceValueLabel: {
    id: 'reviewDeal.invoiceValueLabel',
    defaultMessage: 'Invoice amount',
  },
  basePurchasePriceLabel: {
    id: 'reviewDeal.basePurchasePriceLabel',
    defaultMessage: 'Base purchase price',
  },
  feesAndTaxesLabel: {
    id: 'reviewDeal.feesAndTaxesLabel',
    defaultMessage: 'Fees and taxes',
  },
  backendProductsLabel: {
    id: 'reviewDeal.backendProductsLabel',
    defaultMessage: 'Backend products',
  },
  financableAccessoriesLabel: {
    id: 'reviewDeal.financableAccessoriesLabel',
    defaultMessage: 'Financable accessories',
  },
  rebateLabel: {
    id: 'reviewDeal.rebateLabel',
    defaultMessage: 'Rebate',
  },
  downPaymentLabel: {
    id: 'reviewDeal.downPaymentLabel',
    defaultMessage: 'Down payment',
  },
  participationFeeLabel: {
    id: 'reviewDeal.participationFeeLabel',
    defaultMessage: 'Participation',
  },
  dealerFeeLabel: {
    id: 'reviewDeal.dealerFeeLabel',
    defaultMessage: 'Dealer fee',
  },
  netCashLabel: {
    id: 'reviewDeal.netCashLabel',
    defaultMessage: 'Net cash',
  },
  editLabel: {
    id: 'reviewDeal.editLabel',
    defaultMessage: 'edit information',
  },
  dateSelectInstruction: {
    id: 'reviewDeal.dateSelectInstruction',
    defaultMessage: 'Please select delivery date of unit:',
  },
  deliveryDateLabel: {
    id: 'reviewDeal.deliveryDateLabel',
    defaultMessage: 'Delivery date selected',
  },
  monthsSuffix: {
    id: 'reviewDeal.monthsSuffix',
    defaultMessage: 'months',
  },
  additionalInformation: {
    id: 'reviewDeal.additionalInformation',
    defaultMessage: 'Additional Information',
  },
  primaryUseOfCollateral: {
    id: 'reviewDeal.primaryUseOfCollateral',
    defaultMessage: 'Primary use of collateral',
  },
  personal: {
    id: 'reviewDeal.personal',
    defaultMessage: 'Personal',
  },
  business: {
    id: 'reviewDeal.business',
    defaultMessage: 'Business',
  },
  agriculture: {
    id: 'reviewDeal.agriculture',
    defaultMessage: 'Agriculture',
  },
  optionsIncludedOnUnit: {
    id: 'reviewDeal.optionsIncludedOnUnit',
    defaultMessage: 'Options included on unit (optional)',
  },
  sunMoonRoof: {
    id: 'reviewDeal.sunMoonRoof',
    defaultMessage: 'Sun/Moon Roof',
  },
  airConditioning: {
    id: 'reviewDeal.airConditioning',
    defaultMessage: 'Air-conditioning',
  },
  automaticTransition: {
    id: 'reviewDeal.automaticTransition',
    defaultMessage: 'Automatic transition',
  },
  powerSteering: {
    id: 'reviewDeal.powerSteering',
    defaultMessage: 'Power steering',
  },
  powerDoorLocks: {
    id: 'reviewDeal.powerDoorLocks',
    defaultMessage: 'Power door locks',
  },
  powerSeats: {
    id: 'reviewDeal.powerSeats',
    defaultMessage: 'Power seats',
  },
  powerWindows: {
    id: 'reviewDeal.powerWindows',
    defaultMessage: 'Power windows',
  },
  tiltWheel: {
    id: 'reviewDeal.tiltWheel',
    defaultMessage: 'Tilt wheel',
  },
  vinylTop: {
    id: 'reviewDeal.vinylTop',
    defaultMessage: 'Vinyl top',
  },
  cassette: {
    id: 'reviewDeal.cassette',
    defaultMessage: 'Cassette',
  },
  cruiseControl: {
    id: 'reviewDeal.cruiseControl',
    defaultMessage: 'Cruise control',
  },
  amFmStereo: {
    id: 'reviewDeal.amFmStereo',
    defaultMessage: 'AM/FM stereo',
  },
  compactDiscPlayer: {
    id: 'reviewDeal.compactDiscPlayer',
    defaultMessage: 'Compact disc player',
  },
  noOptions: {
    id: 'reviewDeal.noOptions',
    defaultMessage: 'No options',
  },
  unitAddress: {
    id: 'reviewDeal.unitAddress',
    defaultMessage: 'Is {address} the address where the unit will be stored?',
  },
  yes: {
    id: 'reviewDeal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'reviewDeal.no',
    defaultMessage: 'No',
  },
  primaryAddress: {
    id: 'reviewDeal.unitAddress',
    defaultMessage: "Is {address} still the applicant's primary address?",
  },
  streetAddress: {
    id: 'reviewDeal.streetAddress',
    defaultMessage: 'Street address',
  },
  city: {
    id: 'reviewDeal.city',
    defaultMessage: 'City',
  },
  county: {
    id: 'reviewDeal.county',
    defaultMessage: 'County',
  },
  state: {
    id: 'reviewDeal.state',
    defaultMessage: 'State',
  },
  asIsSale: {
    id: 'reviewDeal.asIsSale',
    defaultMessage: 'As is sale',
  },
});

export default messages;
