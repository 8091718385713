import { defineMessages } from 'react-intl';

const messages = defineMessages({
  body: {
    id: 'footer.body',
    defaultMessage: `*  To contact Aqua call 877-366-8406 or reach out by email to recsupport@aquafinance.com. Aqua's hours of operation are Mon-Fri 7:00AM to 6:00PM CT, Closed on Sat-Sun`,
  },
});

export default messages;
