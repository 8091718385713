import { ResolvedIntlConfig } from 'react-intl';

type I18nConfig = {
  defaultLocale: string;
  availableLocales: string[];
};

const localeCache: Record<string, ResolvedIntlConfig['messages']> = {};

class I18nService {
  public readonly defaultLocale: string;

  public readonly availableLocales: string[];

  constructor(config: I18nConfig) {
    this.defaultLocale = config.defaultLocale;
    this.availableLocales = config.availableLocales;
  }

  async getMessages(lang: string) {
    if (this.availableLocales.includes(lang)) {
      if (localeCache[lang]) {
        return localeCache[lang];
      }

      try {
        const response = await fetch(`/i18n/${lang}.json`);
        const messages = await response.json();
        localeCache[lang] = messages as ResolvedIntlConfig['messages'];
        return localeCache[lang];
      } catch (e) {
        return null;
      }
    }
    return null;
  }
}

export default new I18nService({
  defaultLocale: 'en-US',
  availableLocales: ['en-US', 'en'],
});
